import axios from 'axios';
import { apiRequest } from './apiRequest';

// Fetch all tickets
export const fetchTickets = async () => {
  const response = await apiRequest('get', '/support');
  return response.data;
};

// Create a new ticket
export const createTicket = async (ticketData) => {
    const response = await apiRequest('post', '/support', ticketData);
    return response.data;
  };

  export const createClientTicket = async (ticketData) => {
    const response = await apiRequest('post', '/support/client-tickets', ticketData);
    return response.data;
  };

  