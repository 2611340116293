import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './ResetPassword.css';
import { resetPassword } from '../../api/auth';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import logoLarge from '../../assets/logos/logowhite.svg';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { execute: resetPasswordApi, loading } = useApiWithCsrf(resetPassword);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (newPassword !== confirmPassword) {
      return setError('Passwords do not match.');
    }

    try {
      await resetPasswordApi({ token, email, newPassword });
      setMessage('Password reset successfully! Redirecting to login...');
      setTimeout(() => navigate('/reseller/login'), 3000);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to reset password.');
    }
  };

  return (
    <div>
      <nav className="navbar">
        <div className="container">
          <div className="nav-logo-lgn">
            <img src={logoLarge} alt="Logo" />
          </div>
        </div>
      </nav>

      <div className="reset-password-container">
        <div className="reset-password-box">
          <h2>Reset Password</h2>
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleResetPassword}>
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
              required
            />

            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
              required
            />

            <button type="submit" className="reset-button" disabled={loading}>
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
