import React, { useState, useEffect } from 'react';
import './ActivateClientPopup.css';
import { activateClient, checkSubscription } from '../../api/reseller';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';

function ActivateClientPopup({client, onClose, onActivateSuccess }) {
  const [plan, setPlan] = useState('1 YEAR');
  const [note, setNote] = useState('');
  const [extend, setExtend] = useState(false);
  const [macAddress, setMacAddress] = useState('');
  const { execute: activate, loading, error } = useApiWithCsrf(activateClient);
  const { execute: checkSub, loading: subLoading } = useApiWithCsrf(checkSubscription);
  const [showConfirmExtend, setShowConfirmExtend] = useState(false);
  const [showConfirmForever, setShowConfirForever] = useState(false);
  const [activationMessage, setActivationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submit , setSubmit] = useState(false)

  // Check subscription status on MAC address change
  useEffect(() => {
    if (macAddress) {
      checkExistingSubscription();
    }

    if(client){
      setMacAddress(client.mac)
    }
  }, [macAddress]);

  // Fetch subscription status for client
  const checkExistingSubscription = async () => {
    try {
      setActivationMessage('')
      if (macAddress.length === 17) {
        const { hasActiveSubscription, subscriptionForever , message } = await checkSub(macAddress);
        if (hasActiveSubscription && !extend && !subscriptionForever) {
          setShowConfirmExtend(true);
        }
        if (hasActiveSubscription && !extend && subscriptionForever) {
          setShowConfirForever(true)
        }
        if(!hasActiveSubscription){
          setSubmit(true)
        }
        
      }

    } catch (error) {
      setErrorMessage(error.response.data.message)
      console.error('Failed to check subscription:', error);
    }
  };

  // Handle MAC address input
  const handleMacAddressChange = (e) => {
    let value = e.target.value.replace(/[^a-fA-F0-9]/g, '');
    if (value.length > 12) value = value.substring(0, 12);
    const formattedMac = value.match(/.{1,2}/g)?.join(':') || '';
    setMacAddress(formattedMac);
  };

  // Handle activation process
  const handleActivate = async () => {
    setActivationMessage('')
    try {
      const response = await activate({ macAddress, plan, note, extend });
      console.log(response);
      
      if(!error || error===''){
        // setActivationMessage(response.message);
        setTimeout(() => {
          onActivateSuccess();
          onClose();
        }, 500);
      }
      
    } catch (error) {
      // setActivationMessage('Activation failed: ' + (error.response?.data?.message || error.message));
    }
  };

  // Confirm extension if an existing subscription is found
  const handleConfirmExtend = () => {
    setSubmit(true)
    setExtend(true);
    setShowConfirmExtend(false);
    // handleActivate();
  };

  return (
    <div className="popup-background" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="popup-header">
          <h2>Activation</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className='popup-body'>
          <label>MAC Address</label>
          <input
            disabled={loading || subLoading ||showConfirmForever || showConfirmExtend}
            type="text"
            value={macAddress || client?.mac}
            placeholder="__:__:__:__:__:__"
            onChange={handleMacAddressChange}
            maxLength={17}
          />

          <div className="plan-options">
            <label>
              <input 
              disabled={loading || subLoading ||showConfirmForever || showConfirmExtend}
                type="radio"
                value="1 YEAR"
                checked={plan === '1 YEAR'}
                onChange={(e) => setPlan(e.target.value)}
              />
              1 YEAR (1 CREDIT)
            </label>
            <label>
              <input
              disabled={loading || subLoading ||showConfirmForever || showConfirmExtend}
                type="radio"
                value="FOREVER"
                checked={plan === 'FOREVER'}
                onChange={(e) => setPlan(e.target.value)}
              />
              FOREVER (2.5 CREDITS)
            </label>
          </div>

          <label>Note (optional)</label>
          <input
          disabled={loading || subLoading ||showConfirmForever || showConfirmExtend}
            type="text"
            value={note || client?.resellerNote}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add a note..."
          />

          <label>
            <input
            disabled={loading || subLoading ||showConfirmForever || showConfirmExtend}
              type="checkbox"
              checked={extend}
              onChange={() => setExtend(!extend)}
            />
            Extend (Allow extension if subscription exists)
          </label>

          {(errorMessage|| error) && <p className="error">{error || errorMessage}</p>}
          {activationMessage && <p className="activation-message">{activationMessage}</p>}

          <button onClick={handleActivate} disabled={loading || subLoading || showConfirmForever || showConfirmExtend || macAddress.length!=17 || !submit} className="activate-button">
            {loading || subLoading ? 'Processing...' : 'Activate'}
          </button>
        </div>

        {/* Confirmation popup for extending existing subscription */}
        {showConfirmExtend && (
          <div className="confirm-extend-popup">
            <p>This client already has an active subscription. Do you want to extend it?</p>
            <button onClick={handleConfirmExtend} className="confirm-button">Yes, extend</button>
            <button onClick={() => {
              setShowConfirmExtend(false)
              onClose();
            }} className="cancel-button">Cancel</button>
          </div>
        )}


        {showConfirmForever && (
          <div className="confirm-extend-popup">
            <p>This client already has a Lifetime Plane.</p>
            
            <button onClick={() => {
              setShowConfirmExtend(false)
              onClose();
            }} className="confirm-button">OK</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivateClientPopup;
