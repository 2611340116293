import axios from 'axios';
import { apiRequest } from './apiRequest';
// import apiRequest from './apiRequest';

// Helper function for API requests with CSRF token and credentials
// const apiRequest = async (method, url, data = {}) => {
//   // Extract the CSRF token from the cookies
//   const csrfToken = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1];

//   if (!csrfToken) {
//     throw new Error('CSRF token not found.');
//   }

//   try {
//     return await axios({
//       method,
//       url: `http://localhost:5000/api/reseller${url}`,
//       data,
//       headers: {
//         'x-csrf-token': csrfToken,
//       },
//       withCredentials: true, // Include cookies with the request
//     });
//   } catch (error) {
//     if (error.response?.status === 401 || error.response?.data?.message === 'Invalid token.') {
//       // Handle token expiration or invalid token
//       console.error('Session expired. Redirecting to login...');
//       window.location.href = '/reseller/login'; // Redirect to login page
//     }
//     console.log(error.response?.data?.message);
    
//     throw error; // Rethrow the error for additional handling if necessary
//   }
// };




// Fetch reseller information
export const fetchResellerInfo = async () => {
  const response = await apiRequest('get', '/reseller/dashboard');
  return response.data;
};

// Fetch clients with pagination, filters, and sorting
export const fetchClients = async (params) => {
  const response = await apiRequest('post', '/reseller/clients', params);
  return response.data;
};

// Add or update a client
export const addClient = async (clientData) => {
  const response = await apiRequest('post', '/reseller/add-client', clientData);
  return response.data;
};

// Update playlists for a client
export const updatePlaylists = async (macAddress, playlistData) => {
  const response = await apiRequest('post', '/reseller/update-playlists', { macAddress, playlistData });
  return response.data;
};

// Delete all playlists for a client
export const deleteAllPlaylists = async (macAddress) => {
  const response = await apiRequest('post', '/reseller/delete-all-playlists', { macAddress });
  return response.data;
};

// Activate a client
export const activateClient = async ({ macAddress, plan, note, extend }) => {
  const response = await apiRequest('post', '/reseller/activate-client', { macAddress, plan, note, extend });
  return response.data;
};

// Check a client's subscription status
export const checkSubscription = async (macAddress) => {
  const response = await apiRequest('get', `/reseller/check-subscription/${macAddress}`);
  return response.data;
};

// Fetch the reseller's current credits
export const fetchResellerCredits = async () => {
  const response = await apiRequest('get', '/reseller/credits');
  return response.data;
};

// Update reseller credits
export const updateResellerCredits = async (creditsToAdd) => {
  const response = await apiRequest('put', '/reseller/credits', { creditsToAdd });
  return response.data;
};

// Update a client's note
export const updateClientNote = async (macAddress, note) => {
  const response = await apiRequest('put', '/reseller/update-note', { macAddress, note });
  return response.data;
};


// Lock client
export const lockClient = async (macAddress) => {
    const response = await apiRequest('post', '/reseller/lock-client', { macAddress });
    return response.data;
  };
  
  // Retract client plan
  export const retractClient = async (macAddress) => {
    const response = await apiRequest('post', '/reseller/retract-client', { macAddress });
    return response.data;
  };
  
  // Transfer activation
  export const transferActivation = async (fromMacAddress, toMacAddress) => {
    const response = await apiRequest('post', '/reseller/transfer-activation', { fromMacAddress, toMacAddress });
    return response.data;
  };

// Fetch reseller information
export const fetchResellerProfileInfo = async () => {
  const response = await apiRequest('get', '/reseller/profile');
  return response.data;
};


// update reseller name
export const updateResellerName = async (name) => {
  const response = await apiRequest('put', '/reseller/profile/name', name);
  return response.data;
};


// update reseller name
export const changeResellerPassword = async (currentPassword,newPassword) => {
  const response = await apiRequest('put', '/reseller/profile/password', {currentPassword, newPassword});
  return response.data;
};

// Get client details by MAC address
export const getClientByMac = async (macAddress) => {
  const response = await apiRequest('get' , `/reseller/client/${macAddress}`);
  return response.data;
};

// fetchCredits System  for reseller
export const fetchCreditsSystem = async () => {
  const response = await apiRequest('get', '/reseller/credits-system');
  return response.data;
};


// purchase credits  for reseller
export const purchaseCredits = async (data) => {
  const response = await apiRequest('post', '/reseller/purchase-credits', data);
  return response.data;
};

// fetchTransactions   for reseller
export const fetchTransactions = async () => {
  const response = await apiRequest('get', '/reseller/transactions');
  return response.data;
};

// get order detail  reseller
export const getOrderDetails = async (orderId) => {
  const response = await apiRequest('get', `/reseller/order/${orderId}`);
  return response.data;
};