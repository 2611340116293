import React, { useState, useEffect } from 'react';
import './EditPlaylistPopup.css';
import closeIcone from '../../assets/icons/close.svg';

// Helper function to determine if the URL is of Xtream type
// const isXtreamCode = (url) => {
//   return typeof url === 'string' && url.startsWith('XTREAMCODE|');
// };

function EditPlaylistPopup({ selectedPlaylist, setShowEditPopup, handleSaveEdit, action }) {
  const [name, setName] = useState('');
  const [type, setType] = useState('Link');
  const [url, setUrl] = useState(selectedPlaylist?.url || '');
  const [host, setHost] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);


  // Parse Xtream details if the initial type is Xtream
  // useEffect(() => {
  //   if (type === 'Xtream' && url.startsWith('XTREAMCODE|')) {
  //     const [, parsedHost, parsedUsername, parsedPassword] = url.split('|');
  //     setHost(parsedHost);
  //     setUsername(parsedUsername);
  //     setPassword(parsedPassword);
  //   }
  //   setName(name)
  // }, [type, url]);


  useEffect(() => {
    if (action === 'edit') {
      setName(selectedPlaylist?.name)
      setIsEdit(true)
    }

    if (action === 'add') {
      // setName('')
      setIsAdd(true)
    }
  }, [action]);

  // Handle change in type to reset fields
  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setType(newType);
    setUrl('');
    setHost('');
    setUsername('');
    setPassword('');
  };

  // Format URL before saving based on the type
  const handleSave = () => {
    
  
    let formattedUrl = url;
    if (type === 'Xtream') {
      formattedUrl = `XTREAMCODE|${host}|${username}|${password}`;
    }

    console.log(name ,formattedUrl );
    handleSaveEdit({ name, url: formattedUrl });
  };

  const handleEditAction = ()=>{
    console.log(action);
    
    return action==='edit'
  }

  return (
    <div>
      <div className='popup-bg' onClick={() => setShowEditPopup(false)}></div>
      <div className="edit-popup">
      <div className="info-banner">

      {isEdit && ('Edit Playlist')}
      {isAdd && ('Add a new Playlist')}
          
          
          <button className='btn-ylw btn' onClick={() => setShowEditPopup(false)}>
            <img className='btn-icn' src={closeIcone} alt="close" />
          </button>


      </div>
        
        <label className='mrg-tp-50'>Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label>Type</label>
        <select value={type} onChange={handleTypeChange}>
          <option value="Xtream">Xtream</option>
          <option value="Link">Link</option>
        </select>

        {type === 'Link' ? (
          <>
            <label>URL</label>
            <input
              type="text"
              required
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </>
        ) : (
          <>
            <label>Host</label>
            <input
              type="text"
              required
              value={host}
              onChange={(e) => setHost(e.target.value)}
            />

            <label>Username</label>
            <input
              type="text"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <label>Password</label>
            <input
              type="text"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        )}

         <button className='btn btn-ylw' onClick={handleSave}>
         {isEdit && ( 'Save')}
         {isAdd && ( 'Add')}
          
          </button>
        
        
      </div>
    </div>
  );
}

export default EditPlaylistPopup;
