import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi'; // Spinner icon
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { updateClientNote } from '../../api/reseller';
import './NoteCellRenderer.css';

const NoteCellRenderer = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(props.value || '');
  const [cellStatus, setCellStatus] = useState(''); // '' | 'loading' | 'success' | 'error'

  const { execute, loading } = useApiWithCsrf(updateClientNote);

  const toggleEdit = () => setIsEditing(!isEditing);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    setCellStatus('loading'); // Start loading state

    try {
      await execute(props.data.mac, value);
      setCellStatus('success'); // Set success status
      setTimeout(() => setCellStatus(''), 2000); // Clear success state after 2 seconds
    } catch (error) {
      setCellStatus('error'); // Set error status
      setTimeout(() => setCellStatus(''), 2000); // Clear error state after 2 seconds
      console.error('Error updating note:', error);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      await handleBlur(); // Call handleBlur when Enter is pressed
    }
  };

  return (
    <div className={`note-cell-container ${cellStatus}`}>
      {isEditing ? (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress} // Listen for Enter key
          autoFocus
          disabled={loading}
        />
      ) : (
        <div className="note-cell">
          {cellStatus === 'loading' ? (
            <BiLoaderAlt className="spinner-icon" />
          ) : (
            <>
              <span>{value}</span>
              <button
                onClick={toggleEdit}
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '8px',
                  cursor: 'pointer',
                }}
              >
                <FaPencilAlt />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NoteCellRenderer;
