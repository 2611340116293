import React from 'react';
import './Popup.css';

function Popup({ message, onClose }) {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="msg-popup-content" onClick={(e) => e.stopPropagation()}>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default Popup;
