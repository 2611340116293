import {apiRequest} from './apiRequest';

// Fetch credits system
export const fetchCreditsSystem = async () => {
  const response = await apiRequest('get', '/reseller/credits-system');
  return response.data;
};

// Purchase credits
export const purchaseCredits = async (credits) => {
  const response = await apiRequest('post', '/reseller/purchase-credits', { credits });
  return response.data;
};
