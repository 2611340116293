import React, { useState, useEffect } from 'react';
import './Profile.css';
import { FaEdit, FaSave } from 'react-icons/fa';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { fetchResellerProfileInfo, updateResellerName, changeResellerPassword } from '../../api/reseller';
import Popup from '../../components/Popups/Popup';
import { MdCancel } from "react-icons/md";


function Profile({ reloadResellerInfo }) {
  const [resellerInfo, setResellerInfo] = useState({ name: '', email: '' });
  const [newName, setNewName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);

  const { execute: fetchInfo, loading: infoLoading } = useApiWithCsrf(fetchResellerProfileInfo);
  const { execute: updateName } = useApiWithCsrf(updateResellerName);
  const { execute: updatePassword } = useApiWithCsrf(changeResellerPassword);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [message, setmessage] = useState('');
  const [passError, setPassError] = useState('')

  const handleCloseMessagePopup = () => setShowPopupMessage(false);

  useEffect(() => {
    const loadResellerInfo = async () => {
      try {
        const data = await fetchInfo();
        setResellerInfo(data);
        setNewName(data.name);
      } catch (err) {
        console.error('Failed to load reseller info:', err);
      }
    };
    loadResellerInfo();
  }, []);

  const handleSaveName = async () => {
    setPassError('')
    try {
      const response = await updateName({ name: newName });
      setResellerInfo((prev) => ({ ...prev, name: newName }));
      setIsEditingName(false);
      reloadResellerInfo()
      setmessage(response?.message)
      setShowPopupMessage(true)
    } catch (err) {
      console.error('Failed to update name:', err);
    }
  };

  const handleChangePassword = async () => {
    setPassError('')

    if (!currentPassword || !newPassword || !confirmPassword) {
      setPassError('Please fill all the fields')
      return
    }

    if (newPassword !== confirmPassword) {
      setPassError('Passwords do not match!')
      // alert('Passwords do not match!');
      return;
    }
    try {
      const response = await updatePassword(currentPassword, newPassword);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setmessage(response?.message)
      setShowPopupMessage(true)
      // alert('Password updated successfully.');
    } catch (err) {
      console.error('Failed to update password:', err);
      setPassError(err?.response?.data?.message)
    }
  };

  return (
    <div className="profile-container">

      <div className="profile-info">
        <div className="profile-field">
          <label>Email:</label>
          <span>{resellerInfo.email || 'Loading...'}</span>
        </div>
        <div className="profile-field">
          <label>Name:</label>
          {isEditingName ? (
            <div className="name-edit-container">
              <div>
                <input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />

                <button className="cancel-button" onClick={() => { setIsEditingName(false); }}>
                  <MdCancel />
                </button>
              </div>


              <button className="save-button" onClick={handleSaveName}>
                <FaSave /> Save
              </button>
        
            </div>
          ) : (
            <div className="name-display">
              <span>{resellerInfo.name || 'Loading...'}</span>
              <button
                className="edit-button"
                onClick={() => {
                  setIsEditingName(true)
                  setNewName(resellerInfo.name)
                }}
              >
                <FaEdit /> Edit
              </button>
            </div>
          )}
        </div>
        <div className="profile-field password-section">
          <label>Change Password:</label>
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {passError && (
            <p class="error">{passError}</p>
          )}

          <button className="update-password-button" onClick={handleChangePassword}>
            Update Password
          </button>
        </div>
      </div>


      {showPopupMessage &&
        (<Popup
          message={message}
          onClose={handleCloseMessagePopup}
        />)}
    </div>
  );
}

export default Profile;
