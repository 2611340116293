// Content.js
import React from 'react';
import Customers from '../../sections/Customers/Customers'; // Define this component for each section
import Profile from '../../sections/Profile/Profile';
import CheckMac from '../../sections/CheckMac/CheckMac';
import Support from '../../sections/Support/Support';
import PurchaseCredits from '../../sections/PurchaseCredits/PurchaseCredits';
import BalanceOverview from '../../sections/BalanceOverview/BalanceOverview';
// import Profile from '../sections/Profile';
// import StoreInfo from '../sections/StoreInfo';

function Content({ section, reloadResellerInfo }) {
  switch (section) {
    case 'Customers':
      return <Customers reloadResellerInfo={reloadResellerInfo} />;
    case 'Profile':
      return <Profile reloadResellerInfo={reloadResellerInfo} />;
    case 'Check':
      return <CheckMac />;
    case 'Support':
      return <Support />;

    case 'Purchase':
      return <PurchaseCredits reloadResellerInfo={reloadResellerInfo} />;

    case 'Balance':
      return <BalanceOverview  />;




    // case 'store':
    //   return <StoreInfo />;
    // // Add other sections as needed
    default:
      return <div>Select a section from the sidebar</div>;
  }
}

export default Content;
