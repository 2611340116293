import React, { useState, useEffect, useRef } from 'react';
import './Support.css';
import { fetchTickets, createTicket } from '../../api/support';
import Popup from '../../components/Popups/Popup';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function Support() {
  const [tickets, setTickets] = useState([]);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('Low');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // For success popup
  const [showPopup, setShowPopup] = useState(false); // Controls the visibility of the popup
  const [activeTab, setActiveTab] = useState('new-ticket');
  const [selectedTicket, setSelectedTicket] = useState(null);

  // Ref for the textarea
  const textareaRef = useRef(null);

  // Auto-resize textarea
  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scrollHeight
    }
  };

  const loadTickets = async () => {
    try {
      const data = await fetchTickets();
      setTickets(data);
    } catch (err) {
      console.error('Failed to fetch tickets:', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!subject || !description) {
        setError('Subject and Description are required.');
        setShowPopup(true);
        return;
      }

      await createTicket({ subject, description, priority });
      setSubject('');
      setDescription('');
      setPriority('Low');
      loadTickets(); // Reload tickets
      setActiveTab('my-tickets'); // Switch to "My Tickets" tab after submission
      setSuccessMessage('Ticket created successfully!'); // Set success message
      setShowPopup(true); // Show success popup
    } catch (err) {
      console.error('Failed to create ticket:', err);
      setError('Failed to create ticket.');
      setShowPopup(true);
    }
  };

  useEffect(() => {
    loadTickets();
  }, []);

  // Define column definitions for AG Grid
  const columnDefs = [
    { headerName: 'Subject', field: 'subject', sortable: true, filter: true },
    { headerName: 'Description', field: 'description', sortable: true, filter: true },
    { headerName: 'Priority', field: 'priority', sortable: true, filter: true },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: (params) => {
        const statusClass = `status ${params.value?.toLowerCase()}`;
        return <span className={statusClass}>{params.value}</span>;
      },
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      valueFormatter: (params) =>
        new Date(params.value).toLocaleString(),
      sortable: true,
      filter: true,
    },
  ];

  // Handle row click for detailed popup
  const handleRowClick = (event) => {
    setSelectedTicket(event.data);
  };

  return (
    <div className="support-page">
  

      {/* Tabs */}
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'new-ticket' ? 'active' : ''}`}
          onClick={() => setActiveTab('new-ticket')}
        >
          New Ticket
        </button>
        <button
          className={`tab ${activeTab === 'my-tickets' ? 'active' : ''}`}
          onClick={() => setActiveTab('my-tickets')}
        >
          My Tickets
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'new-ticket' && (
        <div className="new-ticket-tab">
          <form className="support-form" onSubmit={handleSubmit}>
            <label>Subject:</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter subject"
            />

            <label>Description:</label>
            <textarea
              ref={textareaRef}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onInput={handleInput} // Auto-resize handler
              placeholder="Enter detailed description"
              style={{ resize: 'none' }} // Disable manual resizing
            ></textarea>

            <label>Priority:</label>
            <select value={priority} onChange={(e) => setPriority(e.target.value)}>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>

            <button type="submit">Submit</button>
          </form>
        </div>
      )}

      {activeTab === 'my-tickets' && (
        <div className="my-tickets-tab ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
          <AgGridReact
            rowData={tickets}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            domLayout="autoHeight"
            suppressCellFocus={true}
            onRowClicked={handleRowClick} // Handle row click for popup
            overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Loading tickets...</span>`}
            overlayNoRowsTemplate={`<span class="ag-overlay-loading-center">No tickets found</span>`}
          />
        </div>
      )}

      {/* Popup */}
      {showPopup && (
        <Popup
          message={successMessage || error} // Show success or error message
          onClose={() => {
            setShowPopup(false);
            setError('');
            setSuccessMessage('');
          }}
        />
      )}

      {/* Ticket Details Popup */}
      {selectedTicket && (
        <div className="ticket-details-popup-container">
        <div className="ticket-details-popup">
          <div className="popup-content">
            <h3>Ticket Details</h3>
            <p>
              <strong>Subject:</strong> {selectedTicket.subject}
            </p>
            <p>
              <strong>Description:</strong> {selectedTicket.description}
            </p>
            <p>
              <strong>Priority:</strong> {selectedTicket.priority}
            </p>
            <p>
              <strong>Status:</strong> {selectedTicket.status}
            </p>
            <p>
              <strong>Date:</strong> {new Date(selectedTicket.createdAt).toLocaleString()}
            </p>
            <button
              className="close-button"
              onClick={() => setSelectedTicket(null)}
            >
              Close
            </button>
          </div>
          </div>
          </div>
      )}
    </div>
  );
}

export default Support;
