import React, { useState } from 'react';
import './PlaylistCard.css';
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/delete.svg';

function PlaylistCard({ playlist, onEdit, onDelete }) {
  return (
    <div className="playlist-card">

        {/* Middle: Logo based on type */}
        <div className="playlist-logo">
        {/* {playlist?.type === 'Xtream' ? ( */}
          <div className="type-icon code-icon">&lt;/&gt;</div>
        {/* // ) : (
        //   <div className="type-icon link-icon">🔗</div>
        // )} */}
      </div>

      
      {/* Top: Playlist Name */}
      <h3 className="playlist-name">{playlist?.name}</h3>

    

      {/* Bottom: Edit and Delete Buttons */}
      <div className="playlist-actions">
        <button onClick={() => onEdit(playlist)}>
          <img src={editIcon} alt="Edit" />
        </button>
        <button onClick={() => onDelete(playlist?.id)}>
          <img src={deleteIcon} alt="Delete" />
        </button>
      </div>
    </div>
  );
}

export default PlaylistCard;
