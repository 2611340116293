import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Contact.css';
import { createClientTicket } from '../../api/support';
import Popup from '../../components/Popups/Popup';


function Contact() {

    const [tickets, setTickets] = useState([]);
    const [subject, setSubject] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState('Low');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // For success popup
    const [showPopup, setShowPopup] = useState(false); // Controls the visibility of the popup
    const [activeTab, setActiveTab] = useState('new-ticket');
    const [selectedTicket, setSelectedTicket] = useState(null);

    // Ref for the textarea
    const textareaRef = useRef(null);

    // Auto-resize textarea
    const handleInput = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scrollHeight
        }
    };

      // Handle MAC address input
  const handleMacAddressChange = (e) => {
    let value = e.target.value.replace(/[^a-fA-F0-9]/g, '');
    if (value.length > 12) value = value.substring(0, 12);
    const formattedMac = value.match(/.{1,2}/g)?.join(':') || '';
    setMacAddress(formattedMac);
  };

    const handleSubmit = async (e) => {
        console.log('submit');
        
        e.preventDefault();
        try {
            if (!subject || !description || !name|| !email || !macAddress) {
                setError('Please.');
                setShowPopup(true);
                return;
            }

            await createClientTicket({ subject, description , name , email , macAddress , description });
            setSubject('');
            setDescription('');
            setPriority('Low');
            //   loadTickets(); // Reload tickets
            setActiveTab('my-tickets'); // Switch to "My Tickets" tab after submission
            setSuccessMessage('Ticket created successfully!'); // Set success message
            setShowPopup(true); // Show success popup
        } catch (err) {
            console.error('Failed to create ticket:', err);
            setError('Failed to create ticket.');
            setShowPopup(true);
        }
    };

    return (
        <div className="new-ticket-tab">
            <form className="support-form" onSubmit={handleSubmit}>
                <label>Name:</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter you name"
                />

                <label>E-mail:</label>
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                />

                <label>Mac adresse:</label>
                <input
                    type="text"
                    value={macAddress}
                    onChange={handleMacAddressChange}
                    placeholder="Enter your mac adress"
                />

                <label>Subject:</label>
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Enter subject"
                />

                <label>Description:</label>
                <textarea
                    ref={textareaRef}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onInput={handleInput} // Auto-resize handler
                    placeholder="Enter detailed description"
                    style={{ resize: 'none' }} // Disable manual resizing
                ></textarea>


                <button type="submit">Submit</button>
            </form>

             {/* Popup */}
      {showPopup && (
        <Popup
          message={successMessage || error} // Show success or error message
          onClose={() => {
            setShowPopup(false);
            setError('');
            setSuccessMessage('');
          }}
        />
      )}
        </div>
    )

}

export default Contact;