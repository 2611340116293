import axios from 'axios';

export const apiRequest = async (method, url, data = {}) => {
    // Extract the CSRF token from the cookies
    const csrfToken = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1];
  
    if (!csrfToken) {
      throw new Error('CSRF token not found.');
    }
  
    try {
      return await axios({
        method,
        url: `https://svisiontv.com/api${url}`,
        // url: `http://localhost:5000/api${url}`,
        data,
        headers: {
          'x-csrf-token': csrfToken,
        },
        withCredentials: true, // Include cookies with the request
      });
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
  
        // Handle token expiration or invalid token
        if (status === 401 || data?.message === 'Invalid token.') {
          console.error('Session expired. Redirecting to login...');
          window.location.href = '/reseller/login'; // Redirect to login page
        }
  
        // Pass the response message to the caller
        throw new Error(data?.message || `Request failed with status ${status}`);
      }
  
      // For other types of errors (e.g., network errors)
      throw new Error(error.message || 'An unexpected error occurred.');
    }
  };
  