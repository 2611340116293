import React, { useState } from 'react';
import './SettingsPopup.css';
import { FaLock, FaUnlock, FaExchangeAlt, FaTimes, FaLockOpen } from 'react-icons/fa';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function SettingsPopup({ client, onClose, onActivate, onLock, onRetract, onTransferActivation }) {
  const [showConfirmRetract, setShowConfirmRetract] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupFor, setPopupFor] = useState('');

  // Check if retract is allowed
  const currentDate = new Date();
  const activationDate = new Date(client.paymentDate);
  const daysSinceActivation = (currentDate - activationDate) / (1000 * 60 * 60 * 24);
  const isRetractAllowed = daysSinceActivation <= 15;

  // Check if Transfer Activation button should be enabled
  const isTransferAllowed = client.paymentPlan && client.paymentPlan !== 'EXPIRED' && client.paymentPlan !== '';

  const handleLock = () => {
    setPopupFor('lock');
    const lockAction = client.locked ? 'unlock' : 'lock';
    setPopupMessage(`Do you want to ${lockAction} the playlists of ${client.mac}?`);
    setShowConfirmRetract(true);
  };

  const handleRetract = () => {
    setPopupMessage('Are you sure you want to retract this subscription?');
    setPopupFor('retract');
    setShowConfirmRetract(true);
  };

  const handleConfirm = () => {
    if (popupFor === 'lock') {
      onLock(client);
    } else if (popupFor === 'retract') {
      onRetract(client);
    }
    setShowConfirmRetract(false);
  };

  const handleTransferActivation = () => {
    onTransferActivation(client);
  };

  return (
    <div className="settings-popup-background" disabled={showConfirmRetract} onClick={onClose}>
      <div className="settings-popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="settings-popup-header">
          <h3>Settings</h3>
          <button disabled={showConfirmRetract} className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className="mac-address-display1">
          <strong>{client.mac}</strong>
        </div>

        <div className="action-buttons">
          {client.paymentPlan !== 'FOREVER' && (
            <button
              disabled={showConfirmRetract}
              className="action-button activate"
              onClick={() => onActivate(client)}
              data-tooltip="Activate this client's plan"
            >
              <IoMdCheckmarkCircleOutline /> Activate
            </button>
          )}

          <button
            className="action-button lock"
            onClick={handleLock}
            data-tooltip={client.locked ? 'Unlock the client' : 'Lock the client'}
          >
            {client.locked ? <FaLockOpen /> : <FaLock />} {client.locked ? 'Unlock' : 'Lock'}
          </button>

          {client.paymentPlan !== 'TRIAL' && (
            <button
              className={`action-button retract ${!isRetractAllowed ? 'disabled' : ''}`}
              onClick={isRetractAllowed ? handleRetract : undefined}
              disabled={!isRetractAllowed || showConfirmRetract}
              data-tooltip={
                isRetractAllowed
                  ? 'Retract this subscription'
                  : 'Retract is disabled after 15 days of activation'
              }
            >
              <FaTimes /> Retract
            </button>
          )}

          <button
            disabled={!isTransferAllowed || showConfirmRetract}
            className={`action-button transfer ${!isTransferAllowed ? 'disabled' : ''}`}
            onClick={isTransferAllowed ? handleTransferActivation : undefined}
            data-tooltip={
              isTransferAllowed
                ? 'Transfer this subscription to another client'
                : 'Transfer not allowed without an active plan'
            }
          >
            <FaExchangeAlt /> Transfer activation
          </button>
        </div>
      </div>

      {/* Confirmation Popup */}
      {showConfirmRetract && (
        <div className="confirmation-popup">
          <p>{popupMessage}</p>
          <div className="confirmation-buttons">
            <button onClick={handleConfirm} className="confirm-button">
              Yes
            </button>
            <button onClick={() => setShowConfirmRetract(false)} className="cancel-button">
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingsPopup;
