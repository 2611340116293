import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom'; // Use Link for React Router navigation

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 Smart Vision TV - All rights reserved.</p>
      <div className="footer-links">
        <Link to="/terms">Terms of Service</Link> | <Link to="/privacy">Privacy Policy</Link>
      </div>
    </footer>
  );
}

export default Footer;
