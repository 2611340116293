import React, { useState, useEffect, act } from 'react';
import './ManagePlaylist.css';
import axios from 'axios';
import PlaylistCard from '../../components/Cards/PlaylistCard';
import EditPlaylistPopup from '../../components/Popups/EditPlaylistPopup';
import addNewListIcone from '../../assets/icons/list-add.svg';
import Popup from '../../components/Popups/Popup'; // Assuming you have a popup component for feedback messages
import Spinner from '../../components/Spinners/Spinner';
import { addClientPlaylist, checkClientMac, deleteClientPlaylist, fetchClientPlaylist, updateClientPlaylist } from '../../api/client';
import useApi from '../../hooks/useApi';

// const isXtreamCode = (url) => {
//   return typeof url === 'string' && url.startsWith('XTREAMCODE|');
// };


function ManagePlaylist() {
  const [macAddress, setMacAddress] = useState('');
  const [deviceCode, setDeviceCode] = useState('');
  const [isClientVerified, setIsClientVerified] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [name, setName] = useState(selectedPlaylist?.name);



  const [type, setType] = useState('Link');
  const [url, setUrl] = useState(selectedPlaylist?.url || '');
  const [host, setHost] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { execute: checkMac  } = useApi(checkClientMac);
  const { execute: deletePlaylist } = useApi(deleteClientPlaylist);
  const { execute: updatePlaylist } = useApi(updateClientPlaylist);
  const { execute: addPlaylist } = useApi(addClientPlaylist);
  const { execute: getPlaylists } = useApi(fetchClientPlaylist);
  const [action, setAction] = useState('');

  useEffect(() => {
    setAction(action)
    console.log(action);

  }, [action]);


  // Handle input changes
  const handleMacChange = (e) => {
    let value = e.target.value.replace(/[^a-fA-F0-9]/g, ''); // Only allow hex characters
    if (value.length > 12) value = value.substring(0, 12);   // Limit to 12 characters
    const formattedMac = value.match(/.{1,2}/g)?.join(':') || ''; // Format as MAC address
    setMacAddress(formattedMac);
  };

  const handleCheckMac = async (event) => {
    event.preventDefault();

    if (macAddress.length === 17) {
      setIsLoading(true); // Start loading
      try {
        const response = await checkMac(macAddress);

        // MAC exists; prompt for plan selection
     
          
          if (response.locked) {
            setPopupMessage('This device is locked, Unlock it from the app');
            setShowPopup(true);
            
          }
          else{
            setIsClientVerified(true);
            fetchPlaylists();
          }
          
      } catch (err) {
      
        console.log(err?.message);
        
         setPopupMessage(err?.message);
       
        setShowPopup(true);
        setIsClientVerified(false);
      }
      finally {
        setIsLoading(false); // Stop loading
      }

    } else {
      setPopupMessage('Please enter a valid MAC address.');
      setShowPopup(true);
    }
  };

  // Fetch playlists for the client
  const fetchPlaylists = async () => {
    try {
      const response = await getPlaylists(macAddress);
      setPlaylists(response.playlists);
    } catch (error) {
      console.error('Error fetching playlists:', error);
    }
  };


  const handleAddPlaylist = () => {
    setAction('add')
    // console.log(action);

    setShowEditPopup(true);
    console.log('Add new playlist');
    // Implement add logic here, such as opening a modal for adding details
  };

  const handleEdit = (playlist) => {
    setAction('edit')
    // console.log(action);
    setSelectedPlaylist(playlist);
    setName(playlist?.name)
    setShowEditPopup(true);
  };

  // Delete a playlist by ID
  const handleDelete = async (playlistId) => {
    setIsLoading(true)
    try {
      const responce = await deletePlaylist(macAddress,playlistId);
      // setPlaylists(playlists.filter(p => p.id !== playlistId));
      fetchPlaylists()
      setPopupMessage(responce.message)
      setShowPopup(true)
      setIsLoading(false)

    } catch (error) {
      console.error('Error deleting playlist:', error);
      alert('Failed to delete playlist.');
    }
  };

  const checkFieldValid = (list) => {
    let ret = true
    if (list.url === '') {
      setPopupMessage('Please fill all the fields')
      setShowPopup(true)
      ret = false
    }
    else {
      if (list.url.startsWith('XTREAMCODE|')) {
        const [, parsedHost, parsedUsername, parsedPassword] = list.url.split('|');
        if (parsedHost === '' || parsedUsername === '' || parsedPassword === '') {
          setPopupMessage('Please fill all the fields')
          setShowPopup(true)
          ret = false
        }
      }
    }
    return ret
  }

  // Save edits to the selected playlist
  const handleSaveEdit = async (list) => {
    console.log(selectedPlaylist);
    console.log(list);
    let response = null
    if (list.url === '') {
      setPopupMessage('Please fill all the fields')
      setShowPopup(true)

    }

    if (checkFieldValid) {
      try {
        if (action === 'edit') {
          response = await updatePlaylist(macAddress,selectedPlaylist.id, {
            name: list.name,
            url: list.url,
            reseller: ''
          });
        }

        if (action === 'add') {
          const newPlaylist = {
            name: list.name,
            url: list.url,
            reseller: ''
          };

          // Send the new playlist data to the backend
          response = await addPlaylist(macAddress, newPlaylist);

        }


        // Update the playlist in the list with the response data
        // setPlaylists(playlists.map(p => p?.id === selectedPlaylist?.id ? response.data.playlist : p));
        setPopupMessage(response.message)
        setShowPopup(true)
        setShowEditPopup(false);
        fetchPlaylists();
      } catch (error) {
        setPopupMessage('Failed to save playlist changes.')
        setShowPopup(true)
        console.error('Error updating playlist:', error);
        // alert('Failed to save playlist changes.');
      }
    }

    else {
      setPopupMessage('Please fill all the fields')
      setShowPopup(true)
    }


  };



  const onBack = (e) => {
    setIsClientVerified(false);
  }


  return (
    <div className="manage-playlist-container">
      <div className="info-banner">
        <p>ℹ️ SmartVisionTV supports all kinds of M3u content and XtreamCode.</p>
      </div>

      <div className="manage-playlist-form">

        <div className='box-title'>
          Manage Your Playlists
        </div>
        {!isClientVerified ? (
          <form>
            <label htmlFor="macAddress">MAC Address</label>
            <input
              type="text"
              id="macAddress"
              placeholder="_ _: _ _: _ _: _ _: _ _: _ _"
              value={macAddress}
              onChange={handleMacChange}
              maxLength={17}
              disabled={isLoading} // Disable input when loading
            />
            <small>Enter MAC address without colons (e.g., 1144aaff).</small>


            <button type="button" className="next-button" onClick={handleCheckMac} disabled={isLoading || macAddress.length !== 17}>
              {isLoading ? <div className="spinner"></div> : ''} Next
            </button>
          </form>
        ) : (
          <div>
            <div className='flx6h-r'>
              <button disabled={isLoading} onClick={handleAddPlaylist} className="btn btn-ylw">
                <img className='btn-icn' src={addNewListIcone} alt="add" />
                Add New Playlist
              </button>
            </div>

            <div className="manage-playlistCard-container">
              {playlists.map(playlist => (
                <PlaylistCard
                  key={playlist?.id}
                  playlist={playlist}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  disabled={isLoading}
                />
              ))}
            </div>
            <div>
              <button disabled={isLoading} className="back-button" onClick={onBack}>Back  </button>
            </div>

            {showEditPopup && (
              <EditPlaylistPopup
                selectedPlaylist={selectedPlaylist}
                setShowEditPopup={setShowEditPopup}
                handleSaveEdit={handleSaveEdit}
                action={action}
              />

            )}

          </div>

        )}
      </div>

      {/* spinner */}
      {isLoading && <Spinner/>}


      {/* Popup Feedback Message */}
      {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
    </div>
  );
}

export default ManagePlaylist;
