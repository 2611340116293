import React from 'react';
import './TermsOfOnlineSale.css'; // Import the CSS file for styling

const TermsOfOnlineSale = () => {
  return (
    <div className="terms-container">
      <div className="terms-content">
        <h1>Terms of Online Sale</h1>
        <p><strong>Last Updated:</strong> October 2024</p>
        <p>
          We invite you to read these terms and conditions carefully before completing any transactions through the <strong>Smart Vision TV</strong> website (
          <a href="https://svisiontv.com" className="link">https://svisiontv.com</a>). By completing a transaction, you acknowledge your agreement to these terms and conditions.
        </p>

        <h2>Preamble</h2>
        <p>
          These terms govern all online transactions made through the <strong>Smart Vision TV</strong> website. By making a purchase, you accept these terms unconditionally and irrevocably.
        </p>

        <h2>Purpose</h2>
        <p>
          These terms define the rights and obligations of both parties in the context of online sales made via the <strong>Smart Vision TV</strong> website.
        </p>

        <h2>Sales Process</h2>
        <ul>
          <li>The customer selects the service or product they wish to purchase from the website.</li>
          <li>After providing the required information and accepting these terms, the customer is redirected to a secure payment platform to complete the transaction.</li>
          <li>Payment confirmation signifies the conclusion of the sale.</li>
        </ul>

        <h2>Payment Methods</h2>
        <ul>
          <li>Payments are processed through a secure platform. The customer must provide accurate and valid payment details.</li>
          <li>By completing a transaction, the customer ensures they have sufficient funds or credit available.</li>
          <li>Payment terms and conditions of the respective payment provider also apply.</li>
        </ul>

        <h2>Data Privacy</h2>
        <p>
          Information collected during transactions is used solely to process the payment and fulfill the service. All data is handled securely and confidentially, in accordance with our{' '}
          <a href="/privacy" className="link">Privacy Policy</a>. Customers may request access to or correction of their data by contacting{' '}
          <a href="mailto:support@svisiontv.com" className="link">support@svisiontv.com</a>.
        </p>

        <h2>Proof of Payment</h2>
        <p>
          Payment confirmations generated by our payment processor serve as proof of the transaction. Please retain your confirmation for reference.
        </p>

        <h2>Pricing</h2>
        <ul>
          <li>
            <strong>Smart Vision TV</strong> may offer features or upgrades for a fee. Specific prices and terms will be displayed clearly during the purchase process.
          </li>
          <li>Payments are due at the time of purchase and are non-refundable unless otherwise specified in the refund policy.</li>
        </ul>

        <h2>Refund Policy</h2>
        <ul>
          <li>Customers have the right to cancel their purchase within 7 days of the transaction.</li>
          <li>
            To exercise the right of cancellation, contact us via email at{' '}
            <a href="mailto:support@svisiontv.com" className="link">support@svisiontv.com</a> with your transaction details.
          </li>
          <li>Upon cancellation, all associated access to the service will be terminated.</li>
        </ul>

        <h2>Final Terms</h2>
        <ul>
          <li>All transactions are final unless stated otherwise.</li>
          <li>Continued use of the service after purchase indicates acceptance of these terms.</li>
          <li>We reserve the right to modify these terms at any time. Updates will be reflected on this page.</li>
        </ul>

        <h2>Contact Us</h2>
        <p>For any questions or issues regarding these terms, please contact us at:</p>
        <p>
          <strong>Email:</strong>{' '}
          <a href="mailto:support@svisiontv.com" className="link">support@svisiontv.com</a>
        </p>
      </div>
    </div>
  );
};

export default TermsOfOnlineSale;
