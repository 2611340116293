import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './Customers.css';
import AddClientPopup from '../../components/Popups/AddClientPopup';
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { fetchClients as fetchClientsApi } from '../../api/reseller';
import { lockClient as lockClientApi } from '../../api/reseller';
import { retractClient as retractClientApi } from '../../api/reseller';
import { transferActivation as transferActivationApi } from '../../api/reseller';
import ActionButtons from '../../components/Popups/ActionButtons';
import ManagePlaylistPopup from '../../components/Popups/ManagePlaylistPopup';
import SettingsPopup from '../../components/Popups/SettingsPopup';
import ActivateClientPopup from '../../components/Popups/ActivateClientPopup';
import NoteCellRenderer from '../../components/Buttons/NoteCellRenderer';
import { formatDate } from '../../utils/dateHelpers';
import UpdateMacPopup from '../../components/Popups/UpdateMacPopup';
import Popup from '../../components/Popups/Popup';

function Customers({ reloadResellerInfo }) {
  const [rowData, setRowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [showAddClientPopup, setShowAddClientPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showManagePlaylistPopup, setShowManagePlaylistPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [message, setmessage] = useState('');
  const [currentMac, setCurrentMac] = useState();
  const [filterOption, setFilterOption] = useState('all'); // State for the selected filter

  const { execute: fetchClients } = useApiWithCsrf(fetchClientsApi);
  const { execute: lockClient } = useApiWithCsrf(lockClientApi);
  const { execute: retractClient } = useApiWithCsrf(retractClientApi);
  const { execute: transferActivation } = useApiWithCsrf(transferActivationApi);

  // Table Column Definitions
  const columnDefs = [
    { headerName: "MAC", field: "mac", sortable: true, filter: true, width: 320 },
    {
      headerName: "Note",
      field: "resellerNote",
      width: 310 ,
      cellRenderer: NoteCellRenderer,
      editable: false,
    },
    { headerName: "System", field: "system", cellRenderer: SystemRenderer, width: 120 },
    { headerName: "Version", field: "appVersion", sortable: true, width: 90 },
    {
      headerName: "Plan",
      field: "paymentPlan",
      cellRenderer: PlanRenderer,
      width: 110,
    },
    {
      headerName: "Expiration Date",
      field: "subscriptionEnd",
      sortable: true,
      cellRenderer: (params) => {
        if (params.data.paymentPlan === 'FOREVER') {
          return '∞';
        }
        return formatDate(params.value);
      },
    },
    { headerName: "Activation Date", field: "paymentDate", sortable: true, cellRenderer: (params) => formatDate(params.value) },
    { headerName: "Last Cnx", field: "lastCnx", sortable: true, cellRenderer: (params) => formatDate(params.value) },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => (
        <ActionButtons
          data={params.data}
          onEdit={() => handleShowManagePlaylistPopup(params.data)}
          onSettings={() => handleShowSettingsPopup(params.data)}
        />
      ),
      pinned: 'right',
      width: 120,
    },
  ];


  const onActivateSuccess = () => {
    setmessage('Client activated successfully')
    setShowPopupMessage(true)
    loadClients()
    reloadResellerInfo();
  }


  // Fetch clients and populate the grid
  const loadClients = async (filterModel = {}, sortModel = [], startRow = 0, endRow = 100) => {
    // reloadResellerInfo();
    setLoading(true);
    gridApi?.showLoadingOverlay();

    const filterParams = {
      startRow,
      endRow,
      filterModel,
      sortModel,
    };

    try {
      const response = await fetchClients(filterParams);
      const clients = response.rows;

      if (clients.length === 0) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }

      setRowData(clients);
    } catch (error) {
      console.error('Failed to load clients:', error);
      gridApi?.showNoRowsOverlay();
    } finally {
      setLoading(false);
    }
  };

  // Initialize grid and load data
  useEffect(() => {
    if (gridApi) {
      loadClients();
    }
  }, [gridApi]);

  // Search handler
  const handleSearch = () => {
    const filterModel = {
      customFilterTerm: {
        filterType: 'text',
        filter: searchTerm,
      },
    };

    // Combine search and the selected filter
    if (filterOption === 'expiredSoon') {
      filterModel.customFilterSubscriptionStatus = {
        filterType: 'number',
        type: 'equals',
        filter: 2,
      };
    } else if (filterOption === 'expired') {
      filterModel.customFilterSubscriptionStatus = {
        filterType: 'number',
        type: 'equals',
        filter: 3,
      };
    } else if (filterOption === 'all') {
      filterModel.customFilterSubscriptionStatus = {
        filterType: 'number',
        type: 'equals',
        filter: 1,
      };
    }

    loadClients(filterModel);
  };


  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params.api);
    }
  };

  // Popup Handlers
  const handleCloseActivatePopup = () => {
    setShowActivatePopup(false);
    setSelectedClient(null);
  };

  const handleShowManagePlaylistPopup = (clientData) => {
    setSelectedClient(clientData);
    setShowManagePlaylistPopup(true);
  };

  const handleShowSettingsPopup = (clientData) => {
    setSelectedClient(clientData);
    setShowSettingsPopup(true); // Open settings popup
  };

  const handleCloseManagePlaylistPopup = () => {
    setShowManagePlaylistPopup(false);
    setSelectedClient(null);
  };

  const handleCloseSettingsPopup = () => {
    setShowSettingsPopup(false); // Close settings popup
    setSelectedClient(null);
  };


  function SystemRenderer(params) {
    const systemStyle = params.value === 'TIZEN' ? 'system-tizen' : 'system-android';
    return <span className={systemStyle}>{params.value}</span>;
  }

  function PlanRenderer(params) {
    const isExpired = params.value === 'EXPIRED';
    const isTrial = params.value === 'TRIAL';

    return (
      <span
        className={
          isExpired ? 'plan-tag expired' : isTrial ? 'plan-tag trial' : 'plan-tag'
        }
      >
        {params.value}
      </span>
    );
  }


  const handleOpenPopup = (client) => {

    setShowUpdatePopup(true)
    setCurrentMac(client.mac)
  };

  const handleClosePopup = () => setShowUpdatePopup(false);

  const handleCloseMessagePopup = () => setShowPopupMessage(false);


  const handleLock = async (client) => {
    try {
      await lockClient(client.mac);
      // alert('Client locked successfully');
      const lock = client.locked?'unlock':'lock'
      setmessage(`Client device ${lock}ed successfully`)
      setShowPopupMessage(true)
      loadClients();
    } catch (error) {
      console.error('Failed to lock client:', error);
    }
  };

  const handleRetract = async (client) => {
    try {
      await retractClient(client.mac);
      setmessage('Subscription retracted and credits restored')
      setShowPopupMessage(true)
      loadClients();
      reloadResellerInfo();
    } catch (error) {
      console.error('Failed to retract client subscription:', error);
    }
  };

  const handleTransferActivation = async (newMac) => {

    if (selectedClient.mac) {
      try {
        const response = await transferActivation(selectedClient.mac, newMac);
        console.log(response);
        setmessage(response?.message)
        setShowPopupMessage(true)
        // alert('Activation transferred successfully');
      } catch (error) {
        setmessage(error?.response?.data.message)
        setShowPopupMessage(true)
        // console.error('Failed to transfer activation:', error);
      }
      loadClients()
    }
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterOption(selectedFilter);

    // Determine the filter model based on the selected filter
    let filterModel = {};

    if (selectedFilter === 'expiredSoon') {
      filterModel = {
        customFilterSubscriptionStatus: {
          filterType: 'number',
          type: 'equals',
          filter: 2, // 2 represents 'Expired Soon'
        },
      };
    } else if (selectedFilter === 'expired') {
      filterModel = {
        customFilterSubscriptionStatus: {
          filterType: 'number',
          type: 'equals',
          filter: 3, // 3 represents 'Expired'
        },
      };
    } else if (selectedFilter === 'all') {
      filterModel = {
        customFilterSubscriptionStatus: {
          filterType: 'number',
          type: 'equals',
          filter: 1, // 1 represents 'All'
        },
      };
    }

    // Load clients based on the selected filter
    loadClients(filterModel);
  };




  return (
    <div className="customer-table">
      <div className="page-title">
        <h2>Customers</h2>
      </div>
      <div className="table-header">
        <div className="search-zone">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <button onClick={handleSearch} className="btn-mass-host"><FaSearch /></button>
        </div>

        <div className="table-actions">
          <button onClick={() => setShowAddClientPopup(true)} className="btn-new-client"><IoIosAddCircleOutline /> New Client</button>
          <button className="btn-activate" onClick={() => setShowActivatePopup(true)}><IoMdCheckmarkCircleOutline /> Activate</button>
        </div>
      </div>



      {/* Filter Section */}
      <div className="radio-filter-container">
        <div className="radio-button">
          <input
            type="radio"
            id="filter-all"
            name="filter"
            value="all"
            checked={filterOption === 'all'}
            onChange={handleFilterChange}
          />
          <span></span>
          <label htmlFor="filter-all">All</label>
        </div>
        <div className="radio-button">
          <input
            type="radio"
            id="filter-expired-soon"
            name="filter"
            value="expiredSoon"
            checked={filterOption === 'expiredSoon'}
            onChange={handleFilterChange}
          />
          <span></span>
          <label htmlFor="filter-expired-soon">Expired Soon</label>
        </div>
        <div className="radio-button">
          <input
            type="radio"
            id="filter-expired"
            name="filter"
            value="expired"
            checked={filterOption === 'expired'}
            onChange={handleFilterChange}
          />
          <span></span>
          <label htmlFor="filter-expired">Expired</label>
        </div>
      </div>





      <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
          paginationPageSizeSelector={false}
          domLayout="autoHeight"
          suppressCellFocus={true}
          overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Loading clients...</span>`}
          overlayNoRowsTemplate={`<span class="ag-overlay-loading-center">No clients found</span>`}
        />
      </div>

      {showAddClientPopup && (
        <AddClientPopup
          onClose={() => setShowAddClientPopup(false)}
          onClientUpdated={loadClients}
        />
      )}

      {showManagePlaylistPopup && selectedClient && (
        <ManagePlaylistPopup
          macAddress={selectedClient.mac}
          playlists={selectedClient.source || []}
          onClose={handleCloseManagePlaylistPopup}
          onSave={loadClients}
        />
      )}

      {showSettingsPopup && selectedClient && (
        <SettingsPopup
          client={selectedClient}
          onClose={handleCloseSettingsPopup}
          onActivate={setShowActivatePopup}
          onLock={handleLock}
          onRetract={handleRetract}
          onTransferActivation={handleOpenPopup}
        // openTransferMac={handleOpenPopup}
        />
      )}

      {showActivatePopup && (
        <ActivateClientPopup
          client={selectedClient}
          onClose={handleCloseActivatePopup}
          onActivateSuccess={onActivateSuccess}

        />
      )}

      {showUpdatePopup && (
        <UpdateMacPopup
          existingMac={selectedClient.mac}
          onUpdate={handleTransferActivation}
          onClose={handleClosePopup}
        />
      )}


      {showPopupMessage &&
        (<Popup
          message={message}
          onClose={handleCloseMessagePopup} 
          />)}
    </div>
  );
}

export default Customers;
