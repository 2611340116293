import React, { useEffect } from 'react';
import './ThankYou.css'; // Ensure this file matches your design theme
import { FaCheckCircle } from 'react-icons/fa';
import { formatDate } from '../../utils/dateHelpers';

const ThankYou = ({ paymentData, orderId }) => {
  console.log(paymentData, orderId);


  // const {
  //   custom_data: { mac, selectedPlan },
  //   customer: { email },
  //   totals: { amount },
  //   payment: { method },
  // } = paymentData;

  return (
    <div className="thank-you-page" onClick={(event)=>{event.stopPropagation()}}>
      <div className="thank-you-container">
        <FaCheckCircle className="check-icon" />
        <h1>Thank You for Your Purchase!</h1>
        {paymentData.mac && (
          <p>Your subscription has been activated successfully.</p>
        )}

        {!paymentData.mac && (
          <p> Your credits have been purchased successfully and added to your account.</p>
        )}




        <div className="details-section">
          <h2>Payment Details</h2>
          <div className="details">
            <div>
              <strong>Order ID:</strong> <span>{paymentData.orderId}</span>
            </div>

            {!paymentData.mac && (
              <div>
                <strong>Date:</strong> <span>{formatDate(paymentData.createdAt)}</span>
              </div>
            )}

            {paymentData.mac && (
              <div>
                <strong>MAC Address:</strong> <span>{paymentData.mac}</span>
              </div>
            )}

            <div>
              <strong>Plan:</strong> <span>{paymentData.selectedPlan}</span>
            </div>
            {!paymentData.mac && (
              <div>
                <strong>Quantity:</strong> <span>{paymentData.quantity}</span>
              </div>
            )}

            <div>
              <strong>Total Paid:</strong> <span>${paymentData.amount.toFixed(2)}</span>
            </div>
            <div>
              <strong>Email:</strong> <span>{paymentData.customer.email}</span>
            </div>
            <div>
              <strong>Payment Method:</strong>{' '}
              <span>
                {paymentData.payment.method === 'card'
                  ? `Card ending in ${paymentData.payment.cardLast4} (Expiry: ${paymentData.payment.cardExpiryMonth}/${paymentData.payment.cardExpiryYear})`
                  : paymentData.payment.method}
              </span>
            </div>
          </div>
        </div>

        {paymentData.mac && (
          <div className="thank-you-footer">
            <p>If you have any questions, please contact our support team.</p>
            <a href="/" className="back-home-button">
              Go to Homepage
            </a>

            <a href="/manage-playlist" className="back-home-button">
              Manage your playlist
            </a>
          </div>
        )}

      </div>
    </div>
  );
};

export default ThankYou;
