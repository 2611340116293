import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>Privacy Policy</h1>
        <p><strong>Last Updated:</strong> October 2024</p>
        <p>
          At <strong>Smart Vision TV</strong>, your privacy is important to us. This Privacy Policy outlines how we handle information when you use our app or visit 
          <a href="https://svisiontv.com" className="link"> https://svisiontv.com</a>. Our app is designed solely as a platform for users to access their own content, and we do not provide any media or related services.
        </p>
        <h2>Information We Collect</h2>
        <ul>
          <li><strong>Technical Information:</strong> Device ID, operating system version, IP address, and app usage data (e.g., interaction logs).</li>
          <li><strong>Support Information:</strong> Data provided via email (e.g., name, email address, MAC address) for resolving inquiries.</li>
        </ul>
        <p>This data is never sold or shared with third parties.</p>
        <h2>Log Data</h2>
        <p>If an error occurs, we may collect log data (e.g., device details and error reports) via third-party tools. This information is used strictly for troubleshooting and app performance enhancement.</p>
        <h2>Data Retention and Deletion</h2>
        <p>We retain data only as needed. Data provided during support interactions is deleted after inquiries are resolved. No personal data is stored persistently within the app.</p>
        <h2>Children’s Privacy</h2>
        <p>We do not knowingly collect data from users under 13 years old. If you believe a child has provided information, contact us for prompt removal.</p>
        <h2>Contact Us</h2>
        <p>For any questions or concerns about this Privacy Policy, contact us at:</p>
        <p><strong>Email:</strong> <a href="mailto:support@svisiontv.com" className="link">support@svisiontv.com</a></p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
