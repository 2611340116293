import React, { useState } from 'react';
import { FaList } from 'react-icons/fa6';
import { IoSettingsSharp } from "react-icons/io5";
import './ActionButtons.css';

function ActionButtons({ data, onEdit, onSettings ,onActivate }) {
  const [showPopover, setShowPopover] = useState(false);

  const handleEdit = () => {
    onEdit(data);
  };

  const handleSettings = () => {
    onSettings(data);
  };

  return (
    <div
      className="action-buttons-container">
      <button onClick={handleEdit} className='action-button ylw'>
        <FaList />
      </button>
      <button onClick={handleSettings} className='action-button mgn'>
        <IoSettingsSharp />
      </button>
    </div>
  );
}

export default ActionButtons;
