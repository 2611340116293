import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ResellerRegister.css';
import logoLarge from '../../assets/logos/logowhite.svg';
import { registerAcc } from '../../api/auth';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';

function ResellerRegister() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false); // New state for registration status
  const { execute: register } = useApiWithCsrf(registerAcc);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }

    try {
      await register({
        email,
        password,
        name,
      });
      setIsRegistered(true); // Update state to show confirmation screen
    } catch (error) {
      setError(error.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <div>
      <nav className="navbar">
        <div className="container">
          <div className="nav-logo-lgn">
            <Link to="/">
              <img src={logoLarge} alt="Logo" />
            </Link>
          </div>
        </div>
      </nav>
      
      <div className="reseller-register-container">
        {isRegistered ? (
          <div className="confirmation-box">
            <div className="confirmation-icon">✔️</div>
            <h2>Account has been created successfully!</h2>
            <p>Please check your inbox; to verify your registration.</p>
            <div className="confirmation-buttons">
              <Link to="/" className="button">Go to home</Link>
              <Link to="/reseller/login" className="button">Go to login page</Link>
            </div>
          </div>
        ) : (
          <div className="register-box">
            <h2>RESELLER SIGNUP</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleRegister}>
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              <label>Confirm Password:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              <label>Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              
              <div className="login-links">
                <Link to="/reseller/login" className="login">
                  Already have an account? Sign in.
                </Link>
              </div>

              <button type="submit" className="signup-button">Signup</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResellerRegister;
