import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/auth';
const API_URL = 'https://svisiontv.com/api/auth';

export const login = async (data) => {
  const response = await axios.post(`${API_URL}/login`, data, { withCredentials: true });
  return response.data;
};

export const registerAcc = async (data) => {
  const response = await axios.post(`${API_URL}/register`, data, { withCredentials: true });
  return response.data;
};

export const logout = async () => {
  const response = await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
  return response.data;
};

export const forgotPassword = async (data) => {
  return await axios.post(`${API_URL}/forgot-password`, data);
};


// Check if the session is valid
export const checkSession = async () => {
  try {
    const response = await axios.get(`${API_URL}/session`, { withCredentials: true });
  return response.data;
  } catch (error) {
    
  }
  
};


export const resetPassword = async (data) => {
  return await axios.post(`${API_URL}/reset-password`, data);
};