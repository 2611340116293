// Sidebar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import logoLarge from '../../assets/logos/logowhite.svg';
import { GoPeople, GoPerson, GoCodescanCheckmark } from "react-icons/go";
import { IoWalletOutline } from "react-icons/io5";
import { IoMdCart } from "react-icons/io";
import { FaHeadset } from "react-icons/fa6";


// function Sidebar({ onSelectSection, isSidebarOpen }) {
//   return (
//     <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
//       <div className="sidebar-header">
//         <Link to="/">
//           <img src={logoLarge} alt="Logo" className="sidebar-logo" />
//         </Link>
//       </div>
//       <ul className="sidebar-nav">
//         <li onClick={() => onSelectSection('Customers')}>
//           <GoPeople /> Customers
//         </li>
//         <li onClick={() => onSelectSection('Profile')}>
//           <GoPerson /> Profile
//         </li>
//         <li onClick={() => onSelectSection('Check')}>
//           <GoCodescanCheckmark /> Check MAC
//         </li>
//         <li onClick={() => onSelectSection('Balance')}>
//           <IoWalletOutline /> Balance Overview
//         </li>
//         <li onClick={() => onSelectSection('Purchase')}>
//           <IoMdCart /> Purchase Credits
//         </li>
//         <li onClick={() => onSelectSection('Support')}>
//           <FaHeadset /> Support
//         </li>
//       </ul>
//     </div>
//   );
// }


function Sidebar({ onSelectSection , isSidebarOpen , setIsSidebarOpen }) {
  const [activeSection, setActiveSection] = useState("Customers");

  // useEffect(() => {
  //   // Check for active section in localStorage on component mount
  //   const savedSection = localStorage.getItem("activeSection");
  //   if (savedSection) {
  //     setActiveSection(savedSection);
  //     onSelectSection(savedSection); // Trigger the parent callback for initial selection
  //   }
  // }, [onSelectSection]);

  const handleSelectionSection = (sel) => {
    onSelectSection(sel);
    setActiveSection(sel);
    setIsSidebarOpen(false)
    // localStorage.setItem("activeSection", sel); // Save the selection in localStorage
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <Link to="/">
          <img src={logoLarge} alt="Logo" className="sidebar-logo" />
        </Link>
      </div>
      <ul className="sidebar-nav">
        <li className={activeSection === "Customers" ? "active" : ""} onClick={() => handleSelectionSection('Customers')}>
          <GoPeople /><span> Customers</span>
        </li>
        <li className={activeSection === "Profile" ? "active" : ""} onClick={() => handleSelectionSection('Profile')}>
          <GoPerson /><span> Profile</span>
        </li>
        <li className={activeSection === "Check" ? "active" : ""} onClick={() => handleSelectionSection('Check')}>
          <GoCodescanCheckmark /><span> Check MAC</span>
        </li>
        <li className={activeSection === "Balance" ? "active" : ""} onClick={() => handleSelectionSection('Balance')}>
          <IoWalletOutline /> <span>Balance Overview</span>
        </li>
        <li className={activeSection === "Purchase" ? "active" : ""} onClick={() => handleSelectionSection('Purchase')}>
          <IoMdCart /> <span> Purchase Credits</span>
        </li>
        <li className={activeSection === "Support" ? "active" : ""} onClick={() => handleSelectionSection('Support')}>
          <FaHeadset /> <span>Support</span>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
