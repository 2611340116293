/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from '../../components/Popups/Popup'; // Assuming you have a popup component for feedback messages
import './Activation.css';
import { formatDate } from '../../utils/dateHelpers';
import ThankYou from '../ThankYou/ThankYou';
import { activateClientMac, checkClientMac } from '../../api/client';
import useApi from '../../hooks/useApi';
import { Link } from 'react-router-dom'; // Use Link for React Router navigation

function Activation() {
  const [macAddress, setMacAddress] = useState('');
  const [agree, setAgree] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isMacValid, setIsMacValid] = useState(null); // To track MAC address validity
  const [selectedPlan, setSelectedPlan] = useState('1 YEAR');
  const [selectedPlanPriceId, setSelectedPlanPriceId] = useState('pri_01jd08xp27zhxffby8dj0q2scf');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showConfirmExtension, setShowConfirmExtension] = useState(false); // New state for confirmation
  const [expand, setExpand] = useState(false); // New state for confirmation
  const [paymentData, setPaymentData] = useState(null)
  const [orderId, setOrderId] = useState('')
  const { execute: checkMac } = useApi(checkClientMac);
  const { execute: activate } = useApi(activateClientMac);



  const handlePayment = (priceId) => {
    console.log('pay');
    console.log(macAddress, selectedPlan);

    if (window.Paddle) {
      window.Paddle.Checkout.open({
        items: [
          {
            priceId: priceId,
            quantity: 1
          },
        ],
        customData: {
          mac: macAddress,
          selectedPlan: selectedPlan,
          expand: expand
        },
        // discountId : 'dsc_01jd5p88qxd3r2m998pw9fm8pe'
        // customer : email
      })
    }
  }



  useEffect(() => {
    console.log('init');

    if (window.Paddle) {
      console.log('init');
      window.Paddle.Environment.set('sandbox')
      window.Paddle.Initialize({
        token: "test_29e701d3de555bbd9efeacf31c0",
        eventCallback: function (event) {
          console.log(event);
          console.log(macAddress, selectedPlan);
          if (event.name === 'checkout.completed') {
            // save the data to server
            handlePlanSelection(event)
            // console.log(macAddress , selectedPlan);

          }
        }
      })
    }
  }, [])

  // Handle MAC address input
  const handleMacAddressChange = (e) => {
    let value = e.target.value.replace(/[^a-fA-F0-9]/g, '');
    if (value.length > 12) value = value.substring(0, 12);
    const formattedMac = value.match(/.{1,2}/g)?.join(':') || '';
    setMacAddress(formattedMac);
  };


  const handleCheckMac = async (event) => {
    event.preventDefault();

    if (macAddress.length === 17) {
      setIsLoading(true);
      try {
        const response = await checkMac(macAddress);
        console.log(response);


        if (!response.hasActiveSubscription) {
          setIsMacValid(true);
          setSelectedPlan('1 YEAR');
          setSelectedPlanPriceId('pri_01jd08xp27zhxffby8dj0q2scf');
        } else if (response.hasActiveSubscription && !response.subscriptionForever) {
          const exp = formatDate(response.subscriptionEnd);

          // Show a confirmation dialog for extension
          setPopupMessage(`Your device is activated until: ${exp}. Do you want to extend your subscription?`);
          setShowConfirmExtension(true); // Show confirmation dialog
        } else if (response.hasActiveSubscription && response.subscriptionForever) {
          setPopupMessage('Your device is already activated for a lifetime.');
          setShowPopup(true);
        }
        else {
          setPopupMessage(response.message);
          setShowPopup(true);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          setPopupMessage('MAC address not found in our records.');
        } else {
          // setPopupMessage('An error occurred during MAC check.');
        }
        setShowPopup(true);
        setIsMacValid(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setPopupMessage('Please enter a valid MAC address.');
      setShowPopup(true);
    }
  };

  // Handle plan activation
  const handlePlanSelection = async (event) => {
    // event.preventDefault(); // Prevent the page from reloading
    setPaymentData(null)
    console.log('Completed Event : ', event);


    try {
      const response = await activate(
        event.data.custom_data.mac,
        event.data.custom_data.selected_plan,
        event.data.custom_data.expand,
        event,
      );

      if (response.activated) {
        if (window.Paddle) {
          window.Paddle.Checkout.close()
        }


        setIsMacValid(false)
        setPaymentData(response.order)
        setOrderId(response)

        // setPopupMessage(response.data.message);
      }

    } catch (error) {
      setPopupMessage('An error occurred while activating the plan.');
    }
    // setShowPopup(true);
  };

  const handleExtendSubscription = () => {
    setShowConfirmExtension(false); // Close confirmation dialog
    setExpand(true)
    setIsMacValid(true); // Allow the user to proceed to the plan selection step
  };

  const onBack = (e) => {
    setIsMacValid(false);
  }



  return (
    <div className="activation-container">
      {/* Information Banner */}
      <div className="alert-banner">
        <p>⚠️ SmartVisionTV is a dedicated media player. It does not come pre-loaded with any channels upon activation; you are required to add your own channels.</p>
      </div>

      {/* Activation Form */}

      {!paymentData && (<div className="activation-form">
        <div className='box-title'>
          Activate Your Device
        </div>
        {!isMacValid && !showConfirmExtension && !paymentData && (

          <form onSubmit={handleCheckMac} className={`activation-form ${isLoading ? 'disabled' : ''}`}>
            <label htmlFor="macAddress">MAC Address</label>
            <input
              type="text"
              id="macAddress"
              placeholder="_ _: _ _: _ _: _ _: _ _: _ _"
              value={macAddress}
              onChange={handleMacAddressChange}
              maxLength={17}
              disabled={isLoading} // Disable input when loading
            />
            <small>Enter MAC address without colons, e.g., 001122334455.</small>

            {/* Agreement Checkbox */}
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="agree"
                checked={agree}
                onChange={() => setAgree(!agree)}
                disabled={isLoading} // Disable checkbox when loading
              />
              <label htmlFor="agree">
                I agree that SmartVisionTV is a dedicated media player, and no content is provided upon activation.
              </label>
            </div>

            {/* Submit Button */}
            <button className='next' type="submit" disabled={isLoading || !agree || macAddress.length !== 17}>
              {isLoading ? <div className="spinner"></div> : ''} Next
            </button>

          </form>

        )}

        {showConfirmExtension && (
          <div className="confirmation-popup">
            <p>{popupMessage}</p>
            <button onClick={handleExtendSubscription} className="confirm-button">
              Yes, Extend
            </button>
            <button onClick={() => setShowConfirmExtension(false)} className="cancel-button">
              No
            </button>
          </div>
        )}

        {/* Step 2: Plan Selection */}
        {isMacValid && (
          <div>

            <div className="mac-display">
              <span>Your MAC Address</span>
              <strong>{macAddress}</strong>
            </div>

            <center>Select Your Activation Plan</center>
            <div className="plan-options">
              <div
                className={`plan-option ${selectedPlan === '1 YEAR' ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedPlan('1 YEAR')
                  setSelectedPlanPriceId('pri_01jd08xp27zhxffby8dj0q2scf')
                }}
              >
                <p className='tt'>1 YEAR</p>
                <p> 5.15 €</p>
              </div>
              <div
                className={`plan-option ${selectedPlan === 'FOREVER' ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedPlan('FOREVER')
                  setSelectedPlanPriceId('pri_01jd090qs2344vfzykfe70k0qk')
                }}
              >
                <p className='tt'>FOREVER</p>
                <p> 12.85 €</p>
              </div>
            </div>

            <div className="payment-methods">

            </div>

            <div className='payment-form' >

              <div className="terms">
                <input
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                />
                <label>
                  I accept the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
                </label>
              </div>

              <button type="submit" onClick={() => handlePayment(selectedPlanPriceId)} className="pay-button" disabled={!selectedPlan || !acceptedTerms} >
                Pay
              </button>
            </div>

            <button className="back-button" onClick={onBack}>Back  </button>

          </div>
        )}

      </div>)
      }



      {paymentData && <ThankYou paymentData={paymentData} orderId={orderId} />}


      {/* Popup Feedback Message */}
      {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
    </div>
  );
}

export default Activation;
