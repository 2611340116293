import React, { useState, useEffect } from 'react';
import './ManagePlaylistPopup.css';
import { FaTrashAlt } from 'react-icons/fa';
import { IoIosAddCircleOutline } from "react-icons/io";
import { addPlaylist, deleteAllPlaylists, updatePlaylists } from '../../api/reseller';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';

function ManagePlaylistPopup({ macAddress, playlists, onClose, onSave }) {
  const [playlistData, setPlaylistData] = useState([]);
  const [newPlaylistRow, setNewPlaylistRow] = useState({
    type: 'Link', // Default to Link
    url: '',
    host: '',
    user: '',
    password: '',
    name: '',
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const { execute, loading, error } = useApiWithCsrf(updatePlaylists);
  const [errorMessage, setErrorMessage] = useState('')


  // Initialize playlist data with correct type for each playlist
  useEffect(() => {
    const initializedPlaylists = playlists.map(playlist => {
      if (playlist.url?.startsWith("XTREAMCODE|")) {
        const [_, host, user, password] = playlist.url.split("|");
        return {
          ...playlist,
          type: "Xtream",
          host,
          user,
          password,
          url: "", // Clear the URL since it's split into host, user, and password
        };
      } else {
        return { ...playlist, type: "Link" };
      }
    });
    setPlaylistData(initializedPlaylists);
  }, [playlists]);

  const handleAddRow = () => {
    if (playlistData.length < 5)
      setPlaylistData([...playlistData, { ...newPlaylistRow, id: Date.now() }]);
  };

  const handleDeleteRow = (index) => {
    setPlaylistData(playlistData.filter((_, i) => i !== index));
  };

  // const handleSaveChanges = async () => {
  //   // Validation
  //   const newErrorMessages = [];
  //   setErrorMessage('')
  //   const isValid = playlistData.every((playlist, index) => {
  //     if (!playlist.name) {
  //       newErrorMessages[index] = 'Name is required.'

  //       setErrorMessage(errorMessage + 'Name is required.')
  //       return false;
  //     }
  //     if (playlist.type === 'Link' && !playlist.url) {
  //       newErrorMessages[index] = 'URL is required for Link type.';
  //       setErrorMessage(errorMessage + 'URL is required for Link type.')
  //       return false;
  //     }
  //     if (playlist.type === 'Xtream' && (!playlist.host || !playlist.user || !playlist.password)) {
  //       newErrorMessages[index] = 'Host, Username, and Password are required for Xtream type.';
  //       setErrorMessage(errorMessage + 'Host, Username, and Password are required for Xtream type.')
  //       return false;
  //     }
  //     return true;
  //   });

  //   if (!isValid) {
  //     setErrorMessages(newErrorMessages);
  //     return;
  //   } else {
  //     setErrorMessages([]);
  //   }

  //   const updatedPlaylists = playlistData.map(({ id, type, url, host, user, password, name }) => {
  //     const finalUrl = type === 'Xtream' ? `XTREAMCODE|${host}|${user}|${password}` : url;
  //     return { id, type, url: finalUrl, name };
  //   });

  //   try {
  //     await execute(macAddress, updatedPlaylists);
  //     onClose();
  //   } catch (error) {
  //     console.error('Error saving playlists:', error);
  //   }
  // };

  const handleSaveChanges = async () => {
    const newErrorMessages = [];
    setErrorMessage('');

    // Validation checks
    const isValid = playlistData.every((playlist, index) => {
      if (!playlist.name) {
        newErrorMessages[index] = 'Name is required.';
        setErrorMessage(errorMessage + 'Name is required.');
        return false;
      }
      if (playlist.type === 'Link' && !playlist.url) {
        newErrorMessages[index] = 'URL is required for Link type.';
        setErrorMessage(errorMessage + 'URL is required for Link type.');
        return false;
      }
      if (playlist.type === 'Xtream' && (!playlist.host || !playlist.user || !playlist.password)) {
        newErrorMessages[index] = 'Host, Username, and Password are required for Xtream type.';
        setErrorMessage(errorMessage + 'Host, Username, and Password are required for Xtream type.');
        return false;
      }
      return true;
    });

    if (!isValid) {
      setErrorMessages(newErrorMessages);
      return;
    } else {
      setErrorMessages([]);
    }

    const updatedPlaylists = playlistData.map(({ id, type, url, host, user, password, name }) => {
      const finalUrl = type === 'Xtream' ? `XTREAMCODE|${host}|${user}|${password}` : url;
      return { id, type, url: finalUrl, name };
    });

    try {
      await execute(macAddress, updatedPlaylists);
      onClose(); // Close popup after saving

      // Call onSave callback if it exists
      if (onSave) {
        onSave();  // Notify the parent component to refresh client data
      }
    } catch (error) {
      console.error('Error saving playlists:', error);
    }
  };


  const handlePlaylistChange = (index, field, value) => {
    setPlaylistData((prev) =>
      prev.map((playlist, i) =>
        i === index ? { ...playlist, [field]: value } : playlist
      )
    );
  };

  const handleTypeChange = (index, newType) => {
    setPlaylistData((prev) =>
      prev.map((playlist, i) => {
        if (i !== index) return playlist;

        if (newType === "Link") {
          return { ...playlist, type: newType, url: "", host: undefined, user: undefined, password: undefined };
        } else {
          return { ...playlist, type: newType, host: "", user: "", password: "" };
        }
      })
    );
  };

  return (
    <div className="popup-background" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="popup-header">
          <h2>Manage Playlists</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className='popup-body'>
          <div className="mac-address-display">
            <strong>MAC Address: </strong> <span>{macAddress}</span>
          </div>

          <div className="playlist-rows">
            {playlistData.map((playlist, index) => (
              <React.Fragment key={index}>
                {/* Show the line only if it's not the first item */}
                {index > 0 && <hr className="row-divider" />}

                <div className="playlist-row">
                  <div className="rows-d">
                    <div className='t-c'>
                      <select
                        value={playlist.type}
                        onChange={(e) => handleTypeChange(index, e.target.value)}
                      >
                        <option value="Link">Link</option>
                        <option value="Xtream">Xtream</option>
                      </select>
                    </div>

                    <div className='d-c'>
                      {playlist.type === "Link" ? (
                        <input
                          type="text"
                          value={playlist.url}
                          placeholder="Lien"
                          onChange={(e) => handlePlaylistChange(index, 'url', e.target.value)}
                        />
                      ) : (
                        <>
                          <input
                            type="text"
                            value={playlist.host || ""}
                            placeholder="Host"
                            onChange={(e) => handlePlaylistChange(index, 'host', e.target.value)}
                          />
                          <input
                            type="text"
                            value={playlist.user || ""}
                            placeholder="Username"
                            onChange={(e) => handlePlaylistChange(index, 'user', e.target.value)}
                          />
                          <input
                            type="text"
                            value={playlist.password || ""}
                            placeholder="Password"
                            onChange={(e) => handlePlaylistChange(index, 'password', e.target.value)}
                          />
                        </>
                      )}
                    </div>

                    <div className='n-c'>
                      <input
                        type="text"
                        value={playlist.name}
                        placeholder="Nom du playlist"
                        onChange={(e) => handlePlaylistChange(index, 'name', e.target.value)}
                      />
                    </div>
                  </div>

                  <button className="delete-row-button ylw" onClick={() => handleDeleteRow(index)}>
                    <FaTrashAlt />
                  </button>
                </div>
              </React.Fragment>
            ))}

          </div>
          {errorMessage && <p className="error">{errorMessage}</p>}
          <div className='buttons'>
            <button onClick={handleAddRow} className="add-row-button"><IoIosAddCircleOutline />Add </button>


            {error && <p className="error-message">{error}</p>}

            <button onClick={handleSaveChanges} disabled={loading} className="save-button">
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button onClick={() => deleteAllPlaylists(macAddress)} className="delete-all-button">
              Delete All Playlists
            </button>
          </div>
        </div>


      </div>
    </div>
  );
}

export default ManagePlaylistPopup;
