import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
  baseURL: 'http://localhost:5000/api',
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response?.data?.message === 'Invalid token.') {
      // Redirect to login if token is invalid
      const navigate = useNavigate();
      navigate('/reseller/login');
    }
    return Promise.reject(error); // Reject the error for further handling
  }
);

export default axiosInstance;
