// Header.js
import React, { useState, useEffect } from 'react';
import './Header.css';
import { GoPerson } from "react-icons/go";
import { logout } from '../../api/auth';  // Import the logout API call
import { useNavigate } from 'react-router-dom';

function Header({ title, name, credits }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout API
      navigate('/reseller/login'); // Redirect to login after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (showDropdown && !event.target.closest('.user-profile')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showDropdown]);

  return (
    <div className="header">
      <h1>{title}</h1>
      <div className="header-right">
        <div className="credits">
          <span className="credits-badge">Credits: {credits}</span>
        </div>
        <div className="user-profile" onClick={toggleDropdown}>
          <GoPerson className="profile-icon" />
          <span>{name}</span>
          <i className="arrow-down">▼</i>

          {/* Dropdown Menu */}
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={handleLogout}>Logout</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
