import React, { useState, useEffect } from 'react';
import './PurchaseCredits.css';
import { fetchCreditsSystem } from '../../api/credits';
import Popup from '../../components/Popups/Popup';
import { Link } from 'react-router-dom';
import { purchaseCredits } from '../../api/reseller';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import ThankYou from '../../pages/ThankYou/ThankYou';

function PurchaseCredits({ reloadResellerInfo }) {
  const [step, setStep] = useState(1);
  const [creditsSystem, setCreditsSystem] = useState([]);
  const [credits, setCredits] = useState('');
  const [priceId, setPriceId] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState(0);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { execute: purchase } = useApiWithCsrf(purchaseCredits)
  const [paymentData, setPaymentData] = useState(null)
  const [paymentSuccess, setPaymentSuccess] = useState(false)



  useEffect(() => {
    console.log('init');

    if (window.Paddle) {
      console.log('init');
      window.Paddle.Environment.set('sandbox')
      window.Paddle.Initialize({
        token: "test_29e701d3de555bbd9efeacf31c0",
        eventCallback: function (event) {
          if (event.name === 'checkout.completed') {
            // save the data to server

            console.log(event);
            handlePurchase(event)

          }
        }
      })
    }
  }, [])


  // Fetch credits system
  const loadCreditsSystem = async () => {
    try {
      const { creditPlans } = await fetchCreditsSystem();
      setCreditsSystem(creditPlans);
      console.log(creditPlans);

    } catch (error) {
      console.error('Failed to fetch credits system:', error);
    }
  };

  // Find the priceId and calculate quantity based on credits input
  const calculateCostAndPriceId = (value) => {
    // console.log('calculate plan');

    let matchedPlan = null;
    creditsSystem.forEach((plan) => {
      const [min, max] = plan.range.split('→').map((v) => parseInt(v.trim(), 10));
      if (value >= min && (value <= max || max === '>1000')) {
        matchedPlan = plan;
        console.log('match plan : ', matchedPlan);
        const price = value * matchedPlan.priceEUR
        setCost(price)
        setSelectedPlan(matchedPlan)
      }
    });

    if (matchedPlan) {
      setPriceId(matchedPlan.priceId);
      setQuantity(value); // Set quantity based on the entered credits
    } else {
      setPriceId('');
      setQuantity(1);
    }
  };

  // Handle credits input
  const handleCreditsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setCredits(value || '');
    if (value > 0) {
      calculateCostAndPriceId(value);
    } else {
      setPriceId('');
      setQuantity(1);
    }
  };

  // Proceed to the next step
  const handleNextStep = () => {
    if (step === 1) {
      if (!credits || credits <= 0) {
        setPopupMessage('Please enter a valid number of credits.');
        setShowPopup(true);
        return;
      }
    }
    setStep(step + 1);
  };

  // Handle Paddle checkout
  const handlePurchase = async (event) => {
    setPaymentData(null)
    try {
      const response = await purchase(event)
      console.log(response.message);
      if (response.message === "Order added successfully.") {
       
        setPaymentData(response.order)
        if (window.Paddle) {
          window.Paddle.Checkout.close() 
        }
        setPaymentData(response.order)
         setPaymentSuccess(true)
         reloadResellerInfo()
        
      }

    } catch (error) { 
      console.log(error);
      
    }

  };


  const handlePayment = () => {
    console.log('pay');

    if (window.Paddle) {
      window.Paddle.Checkout.open({
        items: [
          {
            priceId: selectedPlan.priceId,
            quantity: quantity
          },
        ],
        customData: {
          selectedPlan: selectedPlan.range,

        },
        // discountId : 'dsc_01jd5p88qxd3r2m998pw9fm8pe'
        // customer : email
      })
    }
  }

  useEffect(() => {
    loadCreditsSystem();
  }, []);

  return (
    <div className="purchase-credits-page">
      {/* Stepper */}
      {/* <div className="stepper-container">
        <div className={`step-item ${step === 1 ? 'active' : ''} ${step > 1 ? 'completed' : ''}`}>
          <div className="step-circle">1</div>
          <div className="step-label">Credits System</div>
        </div>
        <div className={`step-line ${step > 1 ? 'completed' : ''}`}></div>
        <div className={`step-item ${step === 2 ? 'active' : ''} ${step > 2 ? 'completed' : ''}`}>
          <div className="step-circle">2</div>
          <div className="step-label">Payment</div>
        </div>
        <div className={`step-line ${step > 2 ? 'completed' : ''}`}></div>
        <div className={`step-item ${step === 3 ? 'active' : ''}`}>
          <div className="step-circle">3</div>
          <div className="step-label">Confirmation</div>
        </div>
      </div> */}

      {/* Step Content */}
      <div className="step-content">
        {step === 1 && !paymentSuccess && (
          <div className='credit-system-container'>
            <section className="credits-system">
              <h2>Credits System</h2>
              <p>Resellers can now purchase any number of credits they desire, and use these credits towards one-year or lifetime activations.</p>
              <div className="credit-conversion">
                <div className="credit-box">
                  <div className="credit-period">1 YEAR</div>
                  <div className="credit-equals">=</div>
                  <div className="credit-value">1 CREDIT</div>
                </div>
                <div className="credit-box">
                  <div className="credit-period">LIFETIME</div>
                  <div className="credit-equals">=</div>
                  <div className="credit-value">2.5 CREDITS</div>
                </div>
              </div>

            </section>
            <div className='credits-content'>
              <div className="credits-table">
                <table>
                  <thead>
                    <tr>
                      <th>Credits Plan</th>
                      <th>Price (€)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditsSystem.map((plan, index) => (
                      <tr key={index}>
                        <td>{plan.range}</td>
                        <td>{plan.priceEUR.toFixed(2)} €</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="credits-input">
                <label>How many credits do you want to purchase?</label>
                <input
                  type="number"
                  value={credits}
                  onChange={handleCreditsChange}
                  placeholder="Enter credits amount"
                />

                {cost > 0 && (
                  <div className="cost-summary">
                    <p>
                      Total Cost: <strong>{cost.toFixed(2)} €</strong>
                    </p>
                  </div>
                )}

                {/* Navigation Buttons */}
                <div className="step-navigation">
                  {step > 1 && <button onClick={() => setStep(step - 1)}>Back</button>}
                  {step < 3 && <button className='next' onClick={handleNextStep}>Next</button>}
                </div>
              </div>
            </div>
          </div>
        )}

        {step === 2  && !paymentSuccess &&  (
          <div className='desc-bill'>
            <h3>Payment</h3>


            <div
              className={`bill`}
            >
              <div className='crd'>
                <p className='crdt'>{credits} Credits</p>
                <p className='units'>{selectedPlan.priceEUR}€ <span>/unit</span></p>
              </div>

              <p className='total'><strong> TOTAL : {cost.toFixed(2)} €</strong></p>
            </div>

            <div className="terms">
              <input
                type="checkbox"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <label>
                I accept the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
              </label>
            </div>

            <button type="submit" onClick={() => handlePayment()} className="pay-button" disabled={!acceptedTerms} >
              Pay
            </button>


            {/* <p>
              Your total is <strong>{cost.toFixed(2)} €</strong>. Click "Next" to open the Paddle checkout.
            </p> */}
            {/* Navigation Buttons */}
            <div className="step-navigation">
              {step > 1 && <button className='back-button' onClick={() => setStep(step - 1)}>Back</button>}

            </div>
          </div>
        )}

        {paymentSuccess && (
          <div>

            {paymentData && <ThankYou paymentData={paymentData}/>}


            {/* <h3>Confirmation</h3>
            <p>
              Thank you! Your purchase of <strong>{credits}</strong> credits is being processed.
            </p>
            <button className="confirm-button" >
              Confirm Purchase
            </button>
            Navigation Buttons
            <div className="step-navigation">
              {step > 1 && <button onClick={() => setStep(step - 1)}>Back</button>}
              {step < 3 && <button onClick={handleNextStep}>Next</button>}
            </div> */}
          </div>
        )}
      </div>



      {/* Popup */}
      {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
    </div>
  );
}

export default PurchaseCredits;
