import React from 'react';
import './LandingPage.css';
import { CgDanger } from "react-icons/cg";
import samsung from '../../assets/logos/systems/samsung.png';
import lg from '../../assets/logos/systems/lg.png';
import android from '../../assets/logos/systems/android.png'
import firetv from '../../assets/logos/systems/firestick.png'
import vidaa from '../../assets/logos/systems/vidaa.png'
import roku from '../../assets/logos/systems/roku.png'
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineStorefront } from 'react-icons/md';
import logoLarge from '../../assets/logos/logowhite.svg';

const LandingPage = () => {

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/activation')
  }
  return (
    <div className="landing-page">
      {/* Header Section */}
      <header className="landing-header">
        {/* <div className="header-content">
          <h1>Smart Vision TV</h1>
          <p>Your ultimate media player for seamless entertainment.</p>
        </div> */}

        <div className="header-container">
          <div className="header-content">
            <div className="nav-logo">

              <Link to="/" >
                <img src={logoLarge} alt="Logo" />
              </Link>



              {/* <h1>SmartVision TV</h1> */}
            </div>
            <h1>Smart Vision TV</h1>
            <p className='slg'>DISCOVER , STREAM, ENJOY</p>
            <p>Your ultimate media player for seamless entertainment.</p>
            <div className="cta-button">
              <a href="#features" className="btn-get-started">Explore Features</a>
            </div>
          </div>
        </div>
      </header>

      <section className="available-platforms">
        <h2>Available In</h2>
        <div id='features' className="platforms-grid">
          <img src={samsung} alt="Samsung Smart TV" title="Samsung Smart TV" />
          <img src={lg} alt="LG webOS" title="LG webOS" />
          <img src={android} alt="Android TV" title="Android TV" />
          <img src={firetv} alt="Fire TV Stick" title="Fire TV Stick" />
          <img src={vidaa} alt="VIDAA" title="VIDAA" />
          <img src={roku} alt="Roku" title="Roku" />
          {/* <img src="/images/microsoft-store.png" alt="Microsoft Store" title="Microsoft Store" />
        <img src="/images/apple-app-store.png" alt="Apple App Store" title="Apple App Store" /> */}
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <h2>Features</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Simple Interface</h3>
            <p>Enjoy an intuitive and easy-to-use interface for effortless navigation.</p>
          </div>
          <div className="feature-card">
            <h3>Parental Controls</h3>
            <p>Lock and hide channels with a secure PIN for family-friendly viewing.</p>
          </div>
          <div className="feature-card">
            <h3>Favorite Channels</h3>
            <p>Create a list of your favorite channels for quick access.</p>
          </div>
          <div className="feature-card">
            <h3>Multi-Device Support</h3>
            <p>Stream on TVs, smartphones, and tablets without any hassle.</p>
          </div>
          <div className="feature-card">
            <h3>Multi-Language</h3>
            <p>Support for multiple languages to enhance your experience.</p>
          </div>
          <div className="feature-card">
            <h3>Customizable Playlists</h3>
            <p>Organize and manage your playlists to suit your preferences.</p>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="pricing-section">
        <h2>Pricing Plans</h2>
        <div className="pricing-grid">
          <div className="pricing-card">
            <h3>1-Year Plan</h3>
            <p>Perfect for regular users</p>
            <p className="price">55 MAD/year</p>
            <button onClick={handleNavigate} className="btn">Activate 1 Year</button>
          </div>
          <div className="pricing-card">
            <h3>Lifetime Plan</h3>
            <p>One-time payment : lifetime access</p>
            <p className="price">137 MAD/once</p>
            <button onClick={handleNavigate} className="btn">Activate Forever</button>
          </div>
          {/* <div className="pricing-card">
            <h3>Reseller Plan</h3>
            <p>Special discounts for resellers</p>
            <p className="price">Contact Us</p>
            <button className="btn">Discover Reseller Packs</button>
          </div> */}
        </div>
      </section>

      {/* reseller Section */}
      <section className="reseller-section">
        <div className="reseller-content">
          <h2>Do you want to become a Reseller?</h2>
          <p className="highlight">
            Discover our packs with exceptional discounts!
          </p>
        </div>
        <button className="reseller-button">
          <Link to="/reseller/description"> <MdOutlineStorefront />Discover our packs</Link>
        </button>
      </section>

      {/* Disclaimer Section */}
      <section className="disclaimer-section">
        <h2>Disclaimer</h2>
        <p>Smart Vision TV is a standalone media player application and does not provide or host any content.</p>
        <ul className="disclaimer-list">
          <li> <CgDanger /> Smart Vision TV does not supply or solicit audiovisual content to its users.</li>
          <li> <CgDanger /> The app is not connected to any third-party providers or content distributors.</li>
          <li> <CgDanger /> Users are fully responsible for supplying their own content in compliance with copyright laws.</li>
          <li> <CgDanger /> Smart Vision TV does not sell or endorse any subscriptions, playlists, or channel packages.</li>
          <li> <CgDanger /> It is strictly against our policy to support or promote unauthorized streaming of copyrighted material without explicit permission from the copyright owner.</li>
        </ul>
      </section>


    </div>
  );
};

export default LandingPage;
