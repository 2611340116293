import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create a Context to store the CSRF token globally
const CsrfTokenContext = createContext(null);

// Custom hook to access the CSRF token from context
export const useCsrfToken = () => {
  return useContext(CsrfTokenContext);
};

// Provider component that fetches and provides the CSRF token
export const CsrfTokenProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState(null);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        // Fetch the CSRF token from the backend
        const response = await axios.get('http://localhost:5000/api/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken); // Store the token in state
      } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
      }
    };

    // Only fetch the token if it's not already set
    if (!csrfToken) {
      fetchCsrfToken();
    }
  }, [csrfToken]);

  // Provide the CSRF token to any child components
  return (
    <CsrfTokenContext.Provider value={csrfToken}>
      {children}
    </CsrfTokenContext.Provider>
  );
};
