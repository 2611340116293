import { useState } from 'react';
import { useCsrfToken } from '../context/CsrfTokenProvider'; // Import the token hook

const useApiWithCsrf = (apiFunction) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const csrfToken = useCsrfToken(); // Access the global CSRF token

  const execute = async (...params) => {
    setLoading(true);
    setError(null);

    try {
      // Include CSRF token in the headers if it exists
      const headers = csrfToken ? { 'x-csrf-token': csrfToken } : {};
      const result = await apiFunction(...params, headers);
      setLoading(false);
      return result;
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.message || 'An unexpected error occurred');
      throw err;
    }
  };

  return { execute, loading, error };
};

export default useApiWithCsrf;
