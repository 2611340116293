import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/logos/logo.svg';
import logoLarge from '../../assets/logos/logowhite.svg';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  // Update the activePage based on the current path
  const getActivePage = () => {
    switch (location.pathname) {
      case '/':
        return 'home';
      case '/activation':
        return 'activation';
      case '/manage-playlist':
        return 'manage-playlist';
      case '/reseller/description':
        return 'reseller';
      case '/contact':
        return 'contact';
      default:
        return 'home';
    }
  };

  const [activePage, setActivePage] = useState(getActivePage());

  useEffect(() => {
    setActivePage(getActivePage());
  }, [location]); // Update activePage whenever location changes

  const handleLinkClick = (page) => {
    setActivePage(page);
    setIsOpen(false); // Close the drawer when a link is clicked
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="nav-logo">

          <Link to="/" onClick={() => handleLinkClick("home")}>
            <img src={logoLarge} alt="Logo" />
          </Link>



          {/* <h1>SmartVision TV</h1> */}
        </div>



        {/* Menu button for mobile */}
        <button className="menu-button" onClick={toggleDrawer}>
          ☰
        </button>

        {/* Links for larger screens */}
        <ul className="nav-links">
          <li className={activePage === "home" ? "active" : ""}>
            <Link to="/" onClick={() => handleLinkClick("home")}>
              Home
            </Link>
          </li>
          <li className={activePage === "activation" ? "active" : ""}>
            <Link to="/activation" onClick={() => handleLinkClick("activation")}>
              Activation
            </Link>
          </li>
          <li className={activePage === "manage-playlist" ? "active" : ""}>
            <Link to="/manage-playlist" onClick={() => handleLinkClick("manage-playlist")}>
              Manage Playlist
            </Link>
          </li>
          <li className={activePage === "reseller" ? "active" : ""}>
            <Link to="/reseller/description" onClick={() => handleLinkClick("reseller")}>
              Reseller
            </Link>
          </li>
          <li className={activePage === "contact" ? "active" : ""}>
            <Link to="/contact" onClick={() => handleLinkClick("contact")}>
              Contact Us
            </Link>
          </li>
        </ul>

        {/* Sliding drawer for mobile */}
        <div className={`drawer ${isOpen ? 'open' : ''}`}>
          <div className="nav-logolarge">
            <a href='/'>
            <img src={logoLarge} alt="Logo" />
            </a>
              
          
          </div>


          <ul className="drawer-links">
            <li className={activePage === "home" ? "active" : ""}>
              <Link to="/" onClick={() => handleLinkClick("home")}>Home</Link>
            </li>
            <li className={activePage === "activation" ? "active" : ""}>
              <Link to="/activation" onClick={() => handleLinkClick("activation")}>Activation</Link>
            </li>
            <li className={activePage === "manage-playlist" ? "active" : ""}>
              <Link to="/manage-playlist" onClick={() => handleLinkClick("manage-playlist")}>Manage Playlist</Link>
            </li>
            <li className={activePage === "reseller" ? "active" : ""}>
              <Link to="/reseller/description" onClick={() => handleLinkClick("reseller")}>Reseller</Link>
            </li>
            <li className={activePage === "contact" ? "active" : ""}>
              <Link to="/contact" onClick={() => handleLinkClick("contact")}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Overlay for closing the drawer */}
      {isOpen && <div className="overlay" onClick={toggleDrawer}></div>}
    </nav>
  );
}

export default Navbar;
