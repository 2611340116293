import React, { useState } from 'react';
import './UpdateMacPopup.css';
import { FaTimes } from 'react-icons/fa';
import Popup from './Popup';

function UpdateMacPopup({ existingMac, onUpdate, onClose }) {
  const [newMac, setNewMac] = useState('');
  const [transferPlaylists, setTransferPlaylists] = useState(false);
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  // Handle MAC address input
  const handleMacAddressChange = (e) => {
    let value = e.target.value.replace(/[^a-fA-F0-9]/g, '');
    if (value.length > 12) value = value.substring(0, 12);
    const formattedMac = value.match(/.{1,2}/g)?.join(':') || '';
    setNewMac(formattedMac);
  };

  // Handle checkbox toggle
  const handleCheckboxChange = () => {
    setTransferPlaylists((prev) => !prev);
  };

  // Handle Update
  const handleUpdate = () => {
    if (newMac.trim()) {
      onUpdate({ newMac, transferPlaylists });
      onClose()
    } else {
      setMessage('Please enter a valid MAC address.');
      setShowPopup(true);
    }
  };

  return (
    <div className="update-mac-popup-background" onClick={onClose}>
      <div className="update-mac-popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="update-mac-popup-header">
          <h3>Update MAC Address</h3>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className="mac-address-display">
          <p>From MAC Address:</p>
          <strong>{existingMac}</strong>
        </div>

        <div className="new-mac-input">
          <label htmlFor="new-mac">To New MAC Address:</label>
          <input
            id="new-mac"
            type="text"
            placeholder="Enter new MAC address..."
            value={newMac}
            onChange={handleMacAddressChange}
          />
        </div>

        <div className="checkbox-transf-container">
          <label htmlFor="transfer-checkbox-transf" className="custom-checkbox-transf-label">
            <input
              type="checkbox"
              id="transfer-checkbox-transf"
              className="custom-checkbox-transf"
              checked={transferPlaylists}
              onChange={handleCheckboxChange}
            />
            <span className="custom-checkbox-transf-mark"></span>
            Transfer playlists and plan to the new MAC address
          </label>
        </div>

        <div className="popup-actions">
          <button className="action-button update" onClick={handleUpdate} disabled={newMac.length !== 17}>
            Update
          </button>
          <button className="action-button cancel" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
      {showPopup && <Popup message={message} onClose={() => setShowPopup(false)} />}
    </div>
  );
}

export default UpdateMacPopup;
