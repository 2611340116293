import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ForgotPassword.css';
import logoLarge from '../../assets/logos/logowhite.svg';
import { forgotPassword } from '../../api/auth'; // Assuming you have this API endpoint
import useApiWithCsrf from '../../hooks/useApiWithCsrf';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { execute: sendResetEmail, loading } = useApiWithCsrf(forgotPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendResetEmail({ email });
      setMessage('A password reset email has been sent. Please check your inbox.');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to send reset email.');
    }
  };

  return (
    <div>
      <nav className="navbar">
        <div className="container">
          <div className="nav-logo-lgn">
            <Link to="/">
              <img src={logoLarge} alt="Logo" />
            </Link>
          </div>
        </div>
      </nav>

      <div className="forgot-password-container">
        <div className="forgot-password-box">
          <h2>Forgot Password</h2>
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit}>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              required
            />
            <button type="submit" className="reset-button" disabled={loading}>
              {loading ? 'Sending...' : 'Send Reset Email'}
            </button>
          </form>
          <div className="login-links">
            <Link to="/reseller/login" className="login-link">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
