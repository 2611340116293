import React, { useState } from 'react';
import './AddClientPopup.css';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { addClient } from '../../api/reseller';


function AddClientPopup({ onClose, onClientUpdated }) {
    const [note, setNote] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const { execute, loading, error } = useApiWithCsrf(addClient); // useApiWithCsrf hook for addClient API

    // Handle MAC address input
    const handleMacAddressChange = (e) => {
        let value = e.target.value.replace(/[^a-fA-F0-9]/g, '');
        if (value.length > 12) value = value.substring(0, 12);
        const formattedMac = value.match(/.{1,2}/g)?.join(':') || '';
        setMacAddress(formattedMac);
    };

    const handleAddOrUpdateClient = async () => {
        try {
            const clientData = { macAddress, note };
            const response = await execute(clientData); // Call the API using execute from useApi

            if (response && response.client) {
                onClientUpdated(response.client);
                onClose();
            } else {
                // alert('Unexpected response from server.');
            }
        } catch (err) {
            console.error('An error occurred while adding the client:', err);
        }
    };

    return (
        <div>
            <div className="popup-background" onClick={onClose}>
                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <div className="popup-header">
                        <h2>Add New Potential Client</h2>
                        <button className="close-button" onClick={onClose}>×</button>
                    </div>

                    <div className="popup-form">
                        <label>MAC Address</label>
                        <input
                            type="text"
                            value={macAddress}
                            placeholder="_ _: _ _: _ _: _ _: _ _: _ _"
                            onChange={handleMacAddressChange}
                            maxLength={17}
                        />

                        <label>Note (optional)</label>
                        <input
                            type="text"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Enter note"
                        />

                        {error && <p className="error">{error}</p>}

                        <button
                            onClick={handleAddOrUpdateClient}
                            disabled={loading || macAddress.length !== 17}
                            className="submit-button"
                        >
                            {loading ? 'Processing...' : 'Add Client'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddClientPopup;
