import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './BalanceOverview.css';
import axios from 'axios';
import useApiWithCsrf from '../../hooks/useApiWithCsrf';
import { fetchTransactions, getOrderDetails } from '../../api/reseller';
import ThankYou from '../../pages/ThankYou/ThankYou';
import { formatDate } from '../../utils/dateHelpers';

const BalanceOverview = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {execute : getTransactions} = useApiWithCsrf(fetchTransactions)
  const {execute : getOrder} = useApiWithCsrf(getOrderDetails)
  const [gridApi, setGridApi] = useState(null);
  const [paymentData, setPaymentData] = useState(null)

  const columnDefs = [
    { headerName: 'Date', field: 'date', sortable: true, filter: 'agDateColumnFilter', width: 200 , cellRenderer: (params) => formatDate(params.value) },
    { headerName: 'Balance', field: 'balance', sortable: true, filter: true, width: 150 },
    { headerName: 'Type', field: 'balanceType', sortable: true, filter: true, width: 150 },
    { headerName: 'Comment', field: 'comment', sortable: false, filter: false, flex: 1 },
    
  ];

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params.api);
    }
  };

    // Handle row click for detailed popup
    const handleRowClick = async (event) => {
        setPaymentData(null)
        console.log(event.data.orderId);
        try {
            const order = await getOrder(event.data.orderId)
            console.log(order);
            setPaymentData(order)
            
        } catch (error) {
            console.log(error);
            
        }
        // setSelectedOrder(event.data);
      };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const  data  = await getTransactions();
        setRowData(data);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <div className="balance-overview">
    
      <div className="ag-theme-alpine">
      <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
          paginationPageSizeSelector={false}
          domLayout="autoHeight"
          onRowClicked={handleRowClick} // Handle row click for popup
          suppressCellFocus={true}
          overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Loading transactions...</span>`}
          overlayNoRowsTemplate={`<span class="ag-overlay-loading-center">No data found</span>`}
        />
      </div>
      {loading && <div className="loading-overlay">Loading...</div>}

      {paymentData &&
      <div className='order-details' onClick={()=>setPaymentData(null)}>
        <ThankYou paymentData={paymentData}  />
      </div>
      }
    </div>
  );
};

export default BalanceOverview;
