import React from 'react';
import './Spinner.css';

function Spinner() {
  return (
    <div>
        <div className='sp-bg'></div>
        <div className="spinner"></div>
    </div>
 
  );
}

export default Spinner;
